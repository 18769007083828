import {useCallback} from "react"
import {Moment} from "moment"
import {useModel} from "./useModel"
import moment from "moment"
import {toDateOnly} from "helpers"

type DateValue = string | Date
const useDateTimeFormatters = () => {
    const model = useModel()
    const userTimeFormat = model.getUserTimeFormat()
    const userDateFormat = model.getUserDateFormat()
    const formatTime = useCallback(
        (datetime: DateValue) => {
            return moment(datetime).format(userTimeFormat)
        },
        [userTimeFormat]
    )
    const formatDate = useCallback(
        (datetime: DateValue) => {
            return moment(toDateOnly(datetime)).format(userDateFormat)
        },
        [userDateFormat]
    )
    return {
        formatTime,
        formatDate
    }
}

export {useDateTimeFormatters}
