/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from "react"
import {Row, Col} from "antd"
import {useTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader, BaseNewFilter} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassDropAsyncPaginate} from "components/Select"
import {Icon} from "components/Icon"
import {attendanceService, campusesService} from "services"
import {convertTZ, formatCodeName, formatDateOnly, getFullName, handleError, roundTwoDigits} from "helpers"
import styles from "./../common.module.css"
import dailyAttendanceStyles from "./DailyAttendance.module.css"
import {cloneDeep, isEmpty} from "lodash"
import {useDateTimeFormatters, useModel, useVisible} from "hooks"
import {AttendanceEventType, AttendanceStatus} from "types/attendance"
import {BaseButton} from "components"
import moment from "moment"
import classroomAttendanceStyles from "../ClassroomAttendance/ClassroomAttendance.module.css"
import {DailyAttendancePopup} from "../DailyAttendancePopup"
import {useDebounce} from "react-use"
import PopupVerifyAttendance from "sections/academics/instructional/InstructionalLessonDetail/parts/PopupVerifyAttendance"
import {Course} from "types/courses"
import {useFilterStorage} from "hooks/useFilterStorage"
import {FilterKey} from "types/filter"
import {YesNo} from "types/common"
import {exportCsv, exportExcel} from "helpers/export-table"
import {ExportButton, ExportFileType} from "components/ui"
import {BaseRangePicker} from "components/DateTimePicker"
import AddManualDailyAttendance from "./AddManualDailyAttendance/AddManualDailyAttendance"
import {v4 as uuid} from "uuid"
import PhaseSelect from "components/PhaseSelect"
import ShiftSelect from "components/ShiftSelect"

export enum FilterEventType {
    Active = "active",
    InSchool = "inSchool",
    Break = "break",
    Checkout = "checkOut",
    Missed = "missed"
}
const defaultFilter = {
    majorVersionIds: [],
    filterEventType: undefined,
    campuses: [],
    programs: [],
    phases: [],
    shifts: [],
    instructors: [],
    dateRange: []
}

function DailyAttendanceView(props) {
    const {t} = useTranslation(["studentServices"])
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.DailyAttendance,
        defaultFilter
    )
    const popupAddManualDailyAttendance = useVisible()
    const [exporting, setExporting] = useState(false)
    const model = useModel()
    const {formatTime} = useDateTimeFormatters()
    const dateFormat = model.getUserDateFormat()
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        getCurrentData,
        allFields,
        isLoading,
        dispatch,
        filter,
        isLoadedTableFuncs
    } = props
    const {studentProfileId, statsInfo, showAll = false} = props
    const {eventType: filterEventType, majorVersionIds, phaseIds} = filter
    const [selectedAttendance, setSelectedAttendance] = useState<{
        profileId: number
    }>()
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [searchDebounce, setSearchDebounce] = useState(searchValue)

    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])
    const fetchFunction = showAll
        ? attendanceService.getDailyAttendanceList
        : attendanceService.getDailyAttendanceListByDay

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, filter, currentFilter, page, pageSize, searchDebounce])

    const onRefreshData = useCallback(() => {
        getData()
    }, [filter, currentFilter, page, pageSize, searchDebounce])

    const getFilterParams = () => {
        const currentMajorVersionIds = statsInfo.map((info) => info.id)
        const currentPhaseIds = statsInfo.map((info) => info.phaseId)
        const _majorVersionIds =
            majorVersionIds?.filter((majorVersionId) => currentMajorVersionIds.includes(majorVersionId)) ?? []
        const _phaseIds = phaseIds?.filter((phaseId) => currentPhaseIds.includes(phaseId)) ?? []
        const params: any = {
            campusIds: !isEmpty(currentFilter.campuses) ? currentFilter.campuses.map((campus) => campus.id) : undefined,
            instructorProfileIds: !isEmpty(currentFilter.instructors)
                ? currentFilter.instructors.map((instructor) => instructor.profileId)
                : undefined,
            majorVersionIds: _majorVersionIds,
            eventTypes: undefined,
            phaseIds: !isEmpty(currentFilter.phases)
                ? currentFilter.phases.map((phase) => phase.phaseId).concat(_phaseIds)
                : _phaseIds,
            shiftIds: !isEmpty(currentFilter.shifts) ? currentFilter.shifts.map((phase) => phase.shiftId) : undefined
        }

        if (currentFilter?.dateRange?.length) {
            const startDate = moment(currentFilter.dateRange[0])
            startDate.startOf("day")
            const endDate = moment(currentFilter.dateRange[1])
            endDate.endOf("day")
            params.fromDate = startDate.format("YYYY-MM-DD")
            params.toDate = endDate.format("YYYY-MM-DD")
        }

        if (searchDebounce?.length) {
            params.search = searchDebounce
        }

        if ((_majorVersionIds?.length || _phaseIds?.length) && filterEventType) {
            if (filterEventType === FilterEventType.Active) {
                params.eventTypes = []
            } else if (filterEventType === FilterEventType.Break) {
                params.eventTypes = [AttendanceEventType.BreakStart]
            } else if (filterEventType === FilterEventType.Checkout) {
                params.eventTypes = [AttendanceEventType.Checkout]
            } else if (filterEventType === FilterEventType.InSchool) {
                params.eventTypes = [AttendanceEventType.Checkin, AttendanceEventType.BreakEnd]
            } else if (filterEventType === FilterEventType.Missed) {
                params.isMissed = true
            }
        }

        const profileId = model.profileId

        if (!model.isStaffOrAdmin() && profileId) {
            params.studentProfileIds = [profileId]
        } else if (studentProfileId) {
            params.studentProfileIds = [parseInt(studentProfileId)]
        }
        return params
    }

    const renderIconAttendance = (eventType: string) => {
        if (eventType === "check-out") {
            return <Icon icon="CHECKOUT_FILL" color={"#1E90FF"} className={styles.checkOutIcon} />
        } else if (eventType === "check-in") {
            return <Icon icon="CHECKIN_FILL" color={"#1E90FF"} className={styles.rightIcon} />
        } else if (eventType === "break-start") {
            return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.rightIcon} />
        } else {
            return null
        }
    }

    async function getData() {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data, total} = await fetchFunction({
                range: {
                    page,
                    pageSize
                },
                ...params
            })
            const dailyAttendance = (data || []).map((daily) => {
                return normalizeItem(daily)
            })
            props.dispatch({data: dailyAttendance, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const normalizeItem = (daily, isExport: boolean = false, forSubItem: boolean = false) => {
        if (daily.isVerified === "yes" || !model.isStaffOrAdmin() || forSubItem) {
            daily.isDisableCheckBox = true
        }
        let events: any[] = daily.events ?? []
        if (!showAll && !forSubItem) {
            events = (daily.attendances || [])
                .reduce((acc, cur) => acc.concat(...(cur.events || [])), [])
                .sort((a, b) => moment.utc(a.createdAt).diff(moment.utc(b.createdAt)))
        }
        const campusTimezone = daily?.student?.timezone ?? moment.tz.guess()
        const latestEventType = events.length ? events[events.length - 1].eventType : undefined
        const checkInEvents = events.filter((event) => event.eventType === AttendanceEventType.Checkin)
        const checkIn = checkInEvents.length ? checkInEvents[0] : undefined
        const checkOutEvents = events.filter((event) => event.eventType === AttendanceEventType.Checkout)
        const checkOut = checkOutEvents.length ? checkOutEvents[checkOutEvents.length - 1] : undefined
        const missedHours = Math.max(0, getHoursPerDay(daily) - (daily.attendedHours ?? 0))

        const attendanceId = daily.id
        const checkInTime = checkIn ? formatTime(convertTZ(new Date(checkIn.createdAt), campusTimezone)) : null
        const checkInDateTimeRounded = checkIn
            ? formatTime(convertTZ(new Date(checkIn.dateTimeRounded), campusTimezone))
            : null
        const checkOutTime = checkOut ? formatTime(convertTZ(new Date(checkOut.createdAt), campusTimezone)) : null
        const checkOutDateTimeRounded = checkOut
            ? formatTime(convertTZ(new Date(checkOut.dateTimeRounded), campusTimezone))
            : checkOut
        let result = {
            ...daily,
            attendanceId,
            phaseName: daily.student?.phaseName ?? "",
            shiftName: daily.student?.shiftName ?? "",
            id: uuid(),
            checkInHtml: checkInTime,
            checkOutHtml: checkOutTime,
            checkInDateTimeRoundedHtml: checkInDateTimeRounded,
            checkOutDateTimeRoundedHtml: checkOutDateTimeRounded,
            statusHtml: isExport ? latestEventType : renderIconAttendance(latestEventType),
            missedHours: `${roundTwoDigits(missedHours * 60)}`,
            makeUpHours: `${roundTwoDigits(daily.makeUpHours ?? 0) * 60}`,
            attendedHours: `${roundTwoDigits((daily.attendedHours ?? 0) * 60)}`,
            attendHtml: isExport
                ? daily.status === AttendanceStatus.Approved
                    ? `${YesNo.Yes} (Verified: ${daily.isVerified ? YesNo.Yes : YesNo.No})`
                    : `${YesNo.No} (Verified: ${daily.isVerified ? YesNo.Yes : YesNo.No})`
                : renderAttendHtml(daily.status, daily.isVerified)
        }

        if (!forSubItem) {
            result = {
                ...result,
                isOpenChild: false,
                majorVersionName: daily.majorVersion?.versionName ?? "",
                majorVersionCode: daily.majorVersion?.versionCode ?? "",
                dateHtml: isExport ? formatDateOnly(daily.date, dateFormat) : renderDateHtml(result),
                customProfileId: daily.student?.customProfileId ?? "",
                instructorNames: (daily.instructors ?? [])
                    .map(function (instructor) {
                        return instructor.name
                    })
                    .join(","),
                majorName: daily.major?.name ?? "",
                majorCode: daily.major?.code ?? "",
                studentName: getFullName(daily.student)
            }
        }

        return result
    }

    const getHoursPerDay = (item) => {
        if (model.slug === "ogleschool") {
            return item.student?.overallStats?.dailyScheduledHours || item.missedHours
        } else if (item.student) {
            if (item.student.enrollmentStatus === "full_time") {
                return item.majorVersion?.fullTimeHoursPerDay
            } else if (item.student.enrollmentStatus === "half_time") {
                return item.majorVersion?.partTimeHoursPerDay
            } else if (item.student.enrollmentStatus === "three_quarter_time") {
                return item.majorVersion?.threeQtrTimeHours
            } else if (item.student.enrollmentStatus === "less_than_half_time") {
                return item.majorVersion?.lessThanHalfTimeHours
            } else if (item.student.enrollmentStatus === "hybrid") {
                return item.majorVersion?.hybridHoursPerDay
            }
        }
        return 0
    }

    function renderAttendHtml(status, isVerified) {
        if (isVerified === "yes" && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === "yes") {
            return (
                <div className={classroomAttendanceStyles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "dateHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Sis Attn Id",
                field: "sisid",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Student Id",
                field: "customProfileId",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Phase",
                field: "phaseName",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Shift",
                field: "shiftName",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: t("attendance.table.student"),
                field: "studentName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Code",
                field: "majorCode",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Name",
                field: "majorName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Version",
                field: "majorVersionName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Major Version Code",
                field: "majorVersionCode",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check In Time",
                field: "checkInHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check Out Time",
                field: "checkOutHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check In Rounding Time",
                field: "checkInDateTimeRoundedHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check Out Rounding Time",
                field: "checkOutDateTimeRoundedHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.attended"),
                field: "attendedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.missed"),
                field: "missedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "attn",
                field: "attendHtml",
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            "Sis Attn Id",
            "Student Id",
            "Phase",
            "Shift",
            t("attendance.table.student"),
            "Major Code",
            "Major Name",
            "Major Version",
            "Major Version Code",
            "Check In Time",
            "Check Out Time",
            "Check In Rounding Time",
            "Check Out Rounding Time",
            "Status",
            t("attendance.table.attended"),
            t("attendance.table.missed"),
            "attn"
        ]
    }

    const onClickRow = async (data) => {
        if ((data.attendances || []).length > 1) return
        setIsShowPopup(true)
        try {
            setSelectedAttendance({...data, id: data.attendanceId})
        } catch (err) {
            handleError(err)
        }
    }

    const onClosePopup = useCallback(() => {
        setIsShowPopup(false)
        setSelectedAttendance(undefined)
    }, [])

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onApplyFilter = () => {
        applyFilter()
        getData()
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExporting(true)
        try {
            const params = getFilterParams()
            const {data} = await fetchFunction({
                range: {
                    page: 1,
                    pageSize: total
                },
                ...params
            })
            const dailyAttendance = (data || []).map((daily) => {
                return normalizeItem(daily, true)
            })
            console.log("@export", dailyAttendance)
            const filename = `Daily_Attendance_${moment().format("MM_DD_YYYY")}`
            const payload = {
                filename,
                columns: columns.map((col) => col.title.toUpperCase()),
                rows: dailyAttendance.map((item) =>
                    columns.map((col) => {
                        return item[col.field]?.toString() ?? ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setExporting(false)
        }
    }

    const renderDateHtml = (daily) => {
        if (showAll) {
            return <span>{formatDateOnly(daily.date, dateFormat)}</span>
        }
        const attLength = (daily.attendances || []).length
        if (attLength > 1) {
            return (
                <div className={dailyAttendanceStyles.dateWrap}>
                    <div className={dailyAttendanceStyles.arrowWrap} onClick={(e) => onToggleRow(e, daily)}>
                        <Icon
                            icon={daily.isOpenChild ? "ARROW_DOWN" : "ARROW_RIGHT"}
                            className={dailyAttendanceStyles.arrowIcon}
                        />
                    </div>
                    <span className={dailyAttendanceStyles.dateValue}>{formatDateOnly(daily.date, dateFormat)}</span>
                </div>
            )
        }
        if (attLength === 1) {
            return <span>{formatDateOnly(daily.date, dateFormat)}</span>
        } else {
            return <div className={dailyAttendanceStyles.arrowWrap}></div>
        }
    }

    const onToggleRow = (event, rowItem) => {
        const data = getCurrentData()
        event.stopPropagation()
        console.log("@item", {rowItem, data})
        if (rowItem.isOpenChild) {
            console.log("@close")
            const newData = data
                .filter((item) => item.parentId !== rowItem.attendanceId)
                .map((item) => {
                    if (item.id === rowItem.id) {
                        item.isOpenChild = false
                        item.dateHtml = renderDateHtml(item)
                    }
                    return item
                })
            props.dispatch({data: newData})
        } else {
            let findIndex = -1
            let childs = data.find((item, index) => {
                if (item.id === rowItem.id) {
                    findIndex = index
                    return true
                }
                return false
            }).attendances

            if (childs && childs.length) {
                childs = childs.map((childItem) => {
                    childItem.cellTableStyle = styles.cellChildTableStyle
                    childItem.parentId = rowItem.attendanceId
                    childItem.student = rowItem.student
                    return childItem
                })
                let newData = cloneDeep(data)
                newData.splice(findIndex + 1, 0, ...childs)
                newData = newData.map((daily, index) => {
                    if (daily.parentId) {
                        return normalizeItem(daily, false, true)
                    }
                    if (daily.id === rowItem.id) {
                        const newParent = {...daily, isOpenChild: true}
                        return {...newParent, dateHtml: renderDateHtml(newParent)}
                    }
                    return daily
                })
                props.dispatch({data: newData, isLoading: false})
            }
        }
    }

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onShowConfirm = () => {
        setIsShowConfirmVerify(true)
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }

    const headerActions = () => {
        const actions: any = [
            {
                title: (
                    <div className={dailyAttendanceStyles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={dailyAttendanceStyles.verifybtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
        return actions
    }

    return (
        <div style={{paddingBottom: 150}}>
            {model.isStaffOrAdmin() && (
                <BaseNewFilter
                    searchValue={searchValue}
                    onSearchInput={changeSearch}
                    onClick={onApplyFilter}
                    onClickClear={clearFilter}
                    filter={currentFilter}
                    renderRightFilter={() => (
                        <div className={dailyAttendanceStyles.rightFilter}>
                            <BaseButton title="Add Manual" onClick={popupAddManualDailyAttendance.open} />
                            <ExportButton
                                label="EXPORT"
                                isLoading={exporting}
                                onSelect={handleExportFile}
                                availableFileTypes={["csv", "excel"]}
                            />
                        </div>
                    )}>
                    <Row gutter={[40, 32]}>
                        <Col span={12}>
                            <BaseRangePicker
                                placeholder={["Date From", "Date To"]}
                                value={
                                    currentFilter.dateRange
                                        ? [moment(currentFilter.dateRange[0]), moment(currentFilter.dateRange[1])]
                                        : null
                                }
                                onChange={(newValue) => {
                                    onChangeFilter("dateRange", newValue)
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <KlassDropAsyncPaginate
                                value={currentFilter.campuses}
                                onChange={(newValue) => onChangeFilter("campuses", newValue)}
                                loadOptions={onCampusSearchChange}
                                isMulti
                                placeholder="Campus"
                                getOptionLabel={formatCodeName}
                            />
                        </Col>
                        <Col span={12}>
                            <PhaseSelect
                                isClearable
                                isMulti
                                value={currentFilter.phases}
                                onChange={(newValue) => onChangeFilter("phases", newValue ?? null)}
                                placeholder="Phases"
                            />
                        </Col>
                        <Col span={12}>
                            <ShiftSelect
                                isClearable
                                isMulti
                                value={currentFilter.shifts}
                                onChange={(newValue) => onChangeFilter("shifts", newValue ?? null)}
                                placeholder="Shifts"
                            />
                        </Col>
                    </Row>
                </BaseNewFilter>
            )}
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                isShowAction={!!data.find((item) => item.isChecked)}
                actions={headerActions()}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                isShowCheckedColumn
                isShowCheckedColumnHeader={false}
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                onClickRowItem={onClickRow}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <DailyAttendancePopup
                studentProfileIds={selectedAttendance?.profileId ? [selectedAttendance.profileId] : []}
                getListAttendances={getData}
                selectedAttendance={selectedAttendance}
                isShow={isShowPopup}
                onClose={onClosePopup}
            />
            <PopupVerifyAttendance
                data={data}
                type={Course.ProgramAttendanceTrackingType.Daily}
                isShowConfirmVerify={isShowConfirmVerify}
                onCancelConfirm={onCancelConfirm}
                refreshData={getData}
            />
            <AddManualDailyAttendance
                onRefreshData={onRefreshData}
                onClose={() => {
                    popupAddManualDailyAttendance.close()
                    onRefreshData()
                }}
                isShow={popupAddManualDailyAttendance.isVisible}
            />
        </div>
    )
}
export const DailyAttendance = KlassappTableHOC(DailyAttendanceView)
