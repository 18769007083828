import React from "react"
import {useActiveTab} from "hooks"
import {useTranslation} from "react-i18next"
import {Tabs} from "antd"
import {TabHeader} from "components/Tab"
import PaymentsTable from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/PaymentsTab/PaymentsTable/PaymentsTable"
import {FilterKey} from "types/filter"

const {TabPane} = Tabs

export enum Tab {
    All = "all",
    Deposited = "deposited",
    Posted = "posted"
}

const PaymentsTab = () => {
    const {t} = useTranslation(["studentAccount", "common"])
    const [activeSubTab, setActiveSubTab] = useActiveTab(Tab.All, "paymenttab")

    return (
        <div>
            <Tabs className="fullwidth" activeKey={activeSubTab} onChange={setActiveSubTab}>
                <TabPane tab={<TabHeader title="All" />} key={Tab.All}>
                    {activeSubTab === Tab.All && (
                        <PaymentsTable filterKey={FilterKey.StudentAccountStaffPayments} staffReport="all" />
                    )}
                </TabPane>
                <TabPane tab={<TabHeader title="Deposited" />} key={Tab.Deposited}>
                    {activeSubTab === Tab.Deposited && (
                        <PaymentsTable filterKey={FilterKey.StudentAccountStaffPayments} staffReport="deposited" />
                    )}
                </TabPane>
                <TabPane tab={<TabHeader title="Posted" />} key={Tab.Posted}>
                    {activeSubTab === Tab.Posted && (
                        <PaymentsTable filterKey={FilterKey.StudentAccountStaffPayments} staffReport="posted" />
                    )}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default PaymentsTab
