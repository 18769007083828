import React from "react"
import ChargesTable from "./ChargesTable"
import {useParams} from "react-router-dom"
import {FilterKey} from "types/filter"

const ChargesTab = () => {
    const params = useParams<{profileId: string}>()
    const profileId = +params.profileId

    return <ChargesTable profileId={profileId} filterKey={FilterKey.StudentAccountStudentCharges} />
}

export default ChargesTab
