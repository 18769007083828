import React, {useEffect, useState} from "react"
import {Col, Radio, Row} from "antd"
import {Screen} from "components/ui"
import {useTranslation} from "react-i18next"
import styles from "./Chat.module.css"
import {ThreadStyle} from "types/chatSettings"
import {BaseButton} from "components"
import {generalSettingsService} from "services"
import {GeneralSettingField} from "types/settings/general-setting"
import {handleError, toastSuccess} from "helpers"

export default function Chat() {
    const {t} = useTranslation(["chat", "common"])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [threadStyle, setThreadStyle] = useState<ThreadStyle>(ThreadStyle.Default)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            const [chatThreadStyle] = await generalSettingsService.getSettings([GeneralSettingField.ChatThreadStyle])
            if (!chatThreadStyle) {
                setThreadStyle(ThreadStyle.Default)
            } else {
                setThreadStyle(chatThreadStyle.value as ThreadStyle)
            }
        } catch (e) {
            handleError(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onChangeSetting = (key: string, value: any) => {
        if (key === "threadStyle") {
            setThreadStyle(value)
        }
    }

    const onClickSave = async () => {
        try {
            setIsSubmitting(true)
            await generalSettingsService.updateSettings([
                {
                    field: GeneralSettingField.ChatThreadStyle,
                    value: threadStyle
                }
            ])
            toastSuccess("Successfully updated")
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Screen
            htmlTitle={t("chat:chatSettings")}
            header={{
                title: t("chat:chatSettings")
            }}>
            <div>
                <div className={styles.infoChoicesWrap}>
                    <Row>
                        <Col span={10}>
                            <p className={styles.infoTitleChoicesWrap}>{t("chat:threadStyle")}: </p>
                        </Col>
                        <Col span={14}>
                            <Radio.Group
                                className={styles.radioGroup}
                                onChange={(event) => onChangeSetting("threadStyle", event.target.value)}
                                value={threadStyle}>
                                <Radio className={styles.radioBtn__item} value={ThreadStyle.Default}>
                                    {t("chat:default")}
                                </Radio>
                                <Radio className={styles.radioBtn__item} value={ThreadStyle.CommentsAbove}>
                                    {t("chat:commentsAbove")}
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.doneBtn}>
                    <BaseButton
                        title={t("common:action.save")}
                        onClick={onClickSave}
                        loading={isSubmitting || isLoading}
                        disabled={isSubmitting || isLoading}
                    />
                </div>
            </div>
        </Screen>
    )
}
