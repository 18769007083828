/* eslint-disable react-hooks/exhaustive-deps */
import {Communication} from "types/communication"
import styles from "./DirectChat.module.css"
import cx from "classnames"
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import {ReactComponent as NotificationIcon} from "./assets/notification.svg"
import {ReactComponent as SearchIcon} from "./assets/search.svg"
import {useQuery} from "@tanstack/react-query"
import {handleErrorChat, toastSuccess} from "helpers"
import {chatServiceV2} from "services"
import {Icon} from "components"
import {CommunicationContext, DEFAULT_ROOM} from "context/CommunicationContext"
import {Dropdown, Menu, Spin} from "antd"
import {Compose, Message, PinnedIcon, PopupPinnedMessages, UserAvatar} from "sections/NewCommunication/shared"
import {useModel, useVisible} from "hooks"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"
import {useChatTypings, useMessageCompose} from "sections/NewCommunication/hooks"
import {TextPreloader} from "components/TextPreloader"
import SearchChat from "./parts/SearchChat"
import RoomTypes = Communication.RoomTypes
import {getOnlineStatus} from "helpers/chat"
import {ReactComponent as MenuIcon} from "./assets/menu.svg"
import {ConfirmPopup} from "uiKit"

type FragmentMessageOptions = {
    lastMessage?: Communication.Message
    firstMessage?: Communication.Message
    hasMoreDataOnPrev: boolean
    hasMoreDataOnNext: boolean
}

const INIT_FRAGMENT_MESSAGE_OPTIONS: FragmentMessageOptions = {
    hasMoreDataOnPrev: false,
    hasMoreDataOnNext: false
}

type Props = {
    hideHeading?: boolean
}

export function DirectChat({hideHeading = false}: Props) {
    const {
        activeRoom,
        extendedInfoVisible,
        roomGroups,
        changeRoom,
        markDepartmentCampusAsRead,
        viewAs,
        setFoundMessageId
    } = useContext(CommunicationContext)
    const [messages, setMessages] = useState<Communication.Message[]>([])
    const [roomMembers, setRoomMembers] = useState<Communication.RoomUser[]>([])
    const {editMessage, replyMessage, onEditMessage, onReplyMessage} = useMessageCompose()
    const messagesRef = useRef<any>()
    const model = useModel()
    const currentUserId = model.user.id
    const searchBarVisible = useVisible(false)
    const fetchingData = useRef<any>()
    const pinnedPopupVisible = useVisible(false)
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const [changedStatus, setChangedStatus] = useState<Communication.SupportRoomStatus>()
    const confirmChangeStatusPopup = useVisible(false)

    const {typings} = useChatTypings(+activeRoom.roomId)
    const isTyping = useMemo(() => {
        return Object.keys(typings).filter((i) => parseInt(i) !== currentUserId).length > 0
    }, [typings])

    const isSupportRoom = useMemo(() => {
        return [Communication.RoomTypes.OpenedSupportHub, Communication.RoomTypes.ClosedSupportHub].includes(
            activeRoom.type
        )
    }, [activeRoom])

    const [isFirstIntersecting, setFirstIntersecting] = useState<boolean>(false)
    const [isLastIntersecting, setLastIntersecting] = useState<boolean>(false)
    const firstItem = useRef<any>(null)
    const lastItem = useRef<any>(null)
    const observerFirstItem = new IntersectionObserver(([entry]) => setFirstIntersecting(entry.isIntersecting), {
        threshold: 0.25,
        root: document.querySelector("#chatBody"),
        rootMargin: "150px"
    })
    const observerLastItem = new IntersectionObserver(([entry]) => setLastIntersecting(entry.isIntersecting), {
        threshold: 0.25,
        root: document.querySelector("#chatBody"),
        rootMargin: "150px"
    })
    const [fragmentMessageOptions, setFragmentMessageOptions] =
        useState<FragmentMessageOptions>(INIT_FRAGMENT_MESSAGE_OPTIONS)
    const [highlightMessage, setHighlightMessage] = useState<Communication.Message | undefined>()
    const [isSecondRequest, setIsSecondRequest] = useState(false)

    useEffect(() => {
        if (firstItem.current) {
            observerFirstItem.observe(firstItem.current)
        }
        return () => {
            observerFirstItem.disconnect()
        }
    }, [observerFirstItem])

    useEffect(() => {
        if (lastItem.current) {
            observerLastItem.observe(lastItem.current)
        }
        return () => {
            observerLastItem.disconnect()
        }
    }, [observerLastItem])

    useEffect(() => {
        if (
            isFirstIntersecting &&
            isSecondRequest &&
            fetchLatestMessageQuery.isFetched &&
            fragmentMessageOptions.hasMoreDataOnPrev
        ) {
            getData(fragmentMessageOptions.firstMessage.messageId, "backward")
        }
    }, [isFirstIntersecting, fragmentMessageOptions, isSecondRequest])

    useEffect(() => {
        if (
            isLastIntersecting &&
            isSecondRequest &&
            fetchLatestMessageQuery.isFetched &&
            fragmentMessageOptions.hasMoreDataOnNext
        ) {
            getData(fragmentMessageOptions.lastMessage.messageId, "forward")
        }
    }, [isLastIntersecting, fragmentMessageOptions, isSecondRequest])

    useEffect(() => {
        if (highlightMessage) {
            setTimeout(() => {
                scrollToMessageId(highlightMessage.messageId)
            }, 1000)
        }
    }, [highlightMessage])

    const scrollToMessageId = (messageId: number) => {
        setFoundMessageId(messageId)
        const el = document.getElementById(`message-${messageId}`)
        el && el.scrollIntoView({block: "start", behavior: "smooth"})
    }

    useEffect(() => {
        setFragmentMessageOptions(INIT_FRAGMENT_MESSAGE_OPTIONS)
        setRoomMembers([])
        setMessages([])
        searchBarVisible.close()

        const unSubscribeNewMessages = ChatWsV2.registerOnNewMessage(activeRoom.roomId, (message) => {
            handlerNewMessage(message)
        })

        const unSubscribeUpdatedMessages = ChatWsV2.registerOnUpdatedMessage(activeRoom.roomId, (message) => {
            handlerUpdatedMessage(message)
        })

        const unSubscribeUpdatedMessagesReadStatus = ChatWsV2.registerOnUpdatedMessageReadStatus(
            activeRoom.roomId,
            (data) => {
                handlerUpdatedMessageReadStatus(data)
            }
        )

        const unSubscribeSupportUpdated = ChatWsV2.registerOnSupportUpdated(activeRoom.roomId, (data) => {
            handlerSupportUpdated(data)
        })

        return () => {
            unSubscribeNewMessages()
            unSubscribeUpdatedMessages()
            unSubscribeUpdatedMessagesReadStatus()
            unSubscribeSupportUpdated()
        }
    }, [activeRoom.roomId])

    const navigateToMessage = useCallback(
        (_message: Communication.Message) => {
            if (!messages.find((x) => x.messageId === _message.messageId)) {
                setMessages([_message])
                setFragmentMessageOptions({
                    firstMessage: _message,
                    lastMessage: _message,
                    hasMoreDataOnNext: true,
                    hasMoreDataOnPrev: true
                })
            }
            setHighlightMessage({..._message})
        },
        [messages]
    )

    const resetUnreadCount = useCallback(() => {
        if (activeRoom.type === RoomTypes.Department) {
            const room = activeRoom as Communication.DepartmentRoom
            markDepartmentCampusAsRead(room.departmentId, room.campusId, +room.roomId)
            return
        }
    }, [roomGroups, activeRoom])

    const handlerNewMessage = (message: Communication.Message) => {
        if (message.roomId === activeRoom.roomId) {
            setMessages((prev) => [...prev, message])
            const isMe = message.author.userId === currentUserId
            if (model.windowFocused) {
                setHighlightMessage(message)
            }
            if (!isMe) markAsRead(message.messageId)
        }
    }

    const handlerSupportUpdated = (data: any) => {
        if (data.roomId === activeRoom.roomId) {
            const overwriteRoom = {
                ...data,
                type: Communication.SupportRoomStatusToType[data.status]
            }
            changeRoom(overwriteRoom)
        }
    }

    const handlerUpdatedMessage = (message: Communication.Message) => {
        if (message.roomId === activeRoom.roomId) {
            setMessages((prev) => {
                let _all = [...prev]
                const idx = _all.findIndex((x) => x.messageId === message.messageId)
                if (idx >= 0) {
                    _all[idx] = message
                }
                return _all
            })
        }
    }

    const handlerUpdatedMessageReadStatus = ({
        messageIds,
        roomId,
        isRead
    }: {
        messageIds: number[]
        roomId: string | number
        isRead: boolean
    }) => {
        if (roomId === activeRoom.roomId) {
            setMessages((prev) => {
                let _all = [...prev]
                _all = _all.map((x) => {
                    if (messageIds.includes(x.messageId)) {
                        return {...x, isRead}
                    }
                    return x
                })
                return _all
            })
        }
    }

    const removeRoom = async () => {
        try {
            await chatServiceV2.deleteRoom({
                roomIds: [activeRoom.roomId]
            })
            changeRoom(DEFAULT_ROOM)
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const notificationMenu = (
        <Menu className={styles.dropdownMenu}>
            {Communication.NOTIFICATION_OPTIONS.map((x) => (
                <Menu.Item key={x.id} onClick={() => saveNotificationSeting(x.id)}>
                    {x.name}
                </Menu.Item>
            ))}
        </Menu>
    )

    const roomMenu = (
        <Menu className={styles.dropdownMenu}>
            <Menu.Item icon={<Icon icon="TRASH_LINE" className={styles.menuIcon} />} onClick={removeRoom}>
                Delete chat
            </Menu.Item>
        </Menu>
    )

    const saveNotificationSeting = async (_value: Communication.NotificationMode) => {
        const payload = {
            roomId: activeRoom.roomId,
            notifications: _value
        }
        try {
            await chatServiceV2.editRoomNotificationsSettings(payload)
            toastSuccess("Updated setting")
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const getData = async (messageId: number, direction: "backward" | "forward") => {
        try {
            if (fetchingData.current && fetchingData.current.fetching) return

            fetchingData.current = {
                fetching: true
            }
            const {data} = await chatServiceV2.getFragmentMessages({
                roomId: activeRoom.roomId,
                direction,
                messageId
            })
            const _messages = (data || []).reverse().filter((x) => x.messageId !== messageId)
            setMessages((prev) => [..._messages, ...prev])
            if (_messages.length) {
                if (direction === "backward") {
                    setFragmentMessageOptions((prev) => ({
                        ...prev,
                        firstMessage: _messages[_messages.length - 1]
                    }))
                }
                if (direction === "forward") {
                    setFragmentMessageOptions((prev) => ({
                        ...prev,
                        lastMessage: _messages[0]
                    }))
                }
            } else {
                if (direction === "backward") {
                    setFragmentMessageOptions((prev) => ({
                        ...prev,
                        hasMoreDataOnPrev: false
                    }))
                }
                if (direction === "forward") {
                    setFragmentMessageOptions((prev) => ({
                        ...prev,
                        hasMoreDataOnNext: false
                    }))
                }
            }
        } catch (err) {
            handleErrorChat(err)
        } finally {
            fetchingData.current = {
                fetching: false
            }
        }
    }

    const fetchLatestMessageQuery = useQuery(
        ["room-lastest", activeRoom.roomId],
        () =>
            chatServiceV2.getLatestMessages({
                roomId: activeRoom.roomId
            }),
        {
            onSuccess: (resp) => {
                const _messages = (resp.data || []).reverse()
                setMessages(_messages)
                if (_messages.length) {
                    const lastMessage = _messages[_messages.length - 1]
                    setFragmentMessageOptions((prev) => ({
                        ...prev,
                        firstMessage: _messages[0],
                        lastMessage,
                        hasMoreDataOnNext: _messages.length >= 50,
                        hasMoreDataOnPrev: _messages.length >= 50
                    }))
                    setHighlightMessage(lastMessage)
                    markAsRead(lastMessage.messageId)
                    setTimeout(() => {
                        setIsSecondRequest(true)
                    }, 1000)
                }
            },
            onError: (err) => {
                handleErrorChat(err)
            },
            enabled: !!activeRoom.roomId
        }
    )

    useQuery(
        ["room-full-members", activeRoom.roomId],
        () =>
            chatServiceV2.getRoomMembers({
                roomId: activeRoom.roomId,
                range: {
                    pageSize: 1000,
                    page: 1
                }
            }),
        {
            onSuccess: (resp) => {
                setRoomMembers(resp.data || [])
            },
            onError: (err) => {
                handleErrorChat(err)
            },
            enabled: !!activeRoom.roomId && !isSupportRoom
        }
    )

    const markAsRead = (messageId: number) => {
        if (viewAs) return
        const payload = {
            roomId: activeRoom.roomId,
            messageId: messageId,
            direction: "backward"
        }
        ChatWsV2.sendPacket({command: "markAsRead", payload})
        resetUnreadCount()
    }

    const onDeleteMessage = async (messageId: number) => {
        try {
            await chatServiceV2.deleteMessage({
                messageId
            })
            setMessages((prev) => {
                const _messages = [...prev]
                const idx = _messages.findIndex((x) => x.messageId === messageId)
                if (idx >= 0) {
                    _messages.splice(idx, 1)
                }
                return _messages
            })
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const avatar = useMemo(() => {
        if (activeRoom.type === Communication.RoomTypes.DirectChat) {
            return (activeRoom as Communication.DirectChatRoom).image
        }
        if (activeRoom.type === Communication.RoomTypes.Department) {
            return (activeRoom as Communication.DepartmentRoom).user?.photo
        }
        return null
    }, [activeRoom])

    const departmentalUser = useMemo(() => {
        return (roomMembers || []).find((x) => x.isDepartmentChatUser)
    }, [roomMembers])

    const showSupportStatusConfirmation = async (_status: Communication.SupportRoomStatus) => {
        setChangedStatus(_status)
        confirmChangeStatusPopup.open()
    }

    const confirmChangeStatusDescription = useMemo(() => {
        switch (changedStatus) {
            case Communication.SupportRoomStatus.Open:
                return "Are you sure you want to re-open this support request?"
            case Communication.SupportRoomStatus.Cancelled:
                return "Are you sure you want to cancel this support request?"
            default:
                return "Are you sure you want to confirm this support request has been resolved?"
        }
    }, [changedStatus])

    const changeSupportStatus = async () => {
        try {
            confirmChangeStatusPopup.close()
            await chatServiceV2.updateSupportStatus({
                supportId: (activeRoom as Communication.SupportRoom).supportId,
                status: changedStatus
            })
            toastSuccess("Success")
        } catch (e) {
            handleErrorChat(e)
        }
    }

    const supportMenu = () => {
        const isClosedRequest = activeRoom.type === Communication.RoomTypes.ClosedSupportHub
        return (
            <Menu className={styles.dropdownMenu}>
                <Menu.Item
                    icon={<Icon icon="EDIT_LINE" className={styles.menuIcon} />}
                    onClick={() => {
                        showSupportStatusConfirmation(
                            isClosedRequest
                                ? Communication.SupportRoomStatus.Open
                                : Communication.SupportRoomStatus.Resolved
                        )
                    }}>
                    {isClosedRequest ? "Re-Open this request" : "Confirm as resolved"}
                </Menu.Item>
                {!isClosedRequest && (
                    <Menu.Item
                        icon={<Icon icon="FOLLOW_UP" className={styles.menuIcon} />}
                        onClick={() => {
                            showSupportStatusConfirmation(Communication.SupportRoomStatus.Cancelled)
                        }}>
                        Cancel this request
                    </Menu.Item>
                )}
            </Menu>
        )
    }

    const renderTyping = () => {
        if (isTyping) {
            const _user = roomMembers.find((x) => x.userId === parseInt(Object.keys(typings)[0]))
            if (_user) {
                return (
                    <div className={styles.typingContainer}>
                        <UserAvatar user={_user} className={styles.avatar} />
                        <span className={styles.fullName}>{_user.fullName}</span>
                        <TextPreloader size="small" />
                    </div>
                )
            }
        }
        return null
    }

    const onlineAt = useMemo(() => {
        const _room = activeRoom as any
        let onlineAt: Communication.OnlineStatus = _room.onlineAt
        if (_room.type === Communication.RoomTypes.Department) {
            onlineAt = _room.user?.onlineAt
        }
        return onlineAt
    }, [activeRoom])

    const renderOnlineStatus = () => {
        const _room = activeRoom as any
        if (_room.isGroup || isSupportRoom) return null
        return <span className={styles.onlineStatus}>{getOnlineStatus(onlineAt, userDateTimeFormat)}</span>
    }

    return (
        <div className={styles.containerWrap}>
            <div className={styles.container}>
                {!hideHeading && (
                    <>
                        <div className={cx(styles.heading)}>
                            <div className={cx(styles.titleWrap)}>
                                {activeRoom.isGroup ? (
                                    <Icon
                                        icon="PEOPLE"
                                        className={cx(styles.avatarWrap, {
                                            [styles.hasStudentAvatarWrap]: activeRoom?.jsonData?.hasStudentsMembers
                                        })}
                                    />
                                ) : (
                                    <div className={styles.onlineWrap}>
                                        <img
                                            src={avatar || "/image/DefaultAvatar.png"}
                                            alt=""
                                            className={styles.avatarWrap}
                                        />
                                        {!isSupportRoom && (
                                            <div
                                                className={cx(styles.onlineDot, {
                                                    [styles.offlineDot]: onlineAt !== "online"
                                                })}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className={styles.roomInfoWrap}>
                                    <span className={styles.title}>{activeRoom.name}</span>
                                    {renderOnlineStatus()}
                                </div>
                            </div>
                            <div className={styles.roomActionWrap}>
                                {isSupportRoom ? (
                                    <>
                                        <Dropdown overlay={supportMenu} trigger={["click"]} className={styles.iconWrap}>
                                            <MenuIcon className={styles.icon} />
                                        </Dropdown>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={styles.iconWrap}
                                            onClick={() => {
                                                if (extendedInfoVisible.isVisible) extendedInfoVisible.close()
                                                searchBarVisible.toggle()
                                            }}>
                                            <SearchIcon className={styles.icon} />
                                        </div>
                                        {!viewAs && (
                                            <Dropdown
                                                overlay={notificationMenu}
                                                trigger={["click"]}
                                                className={styles.iconWrap}>
                                                <NotificationIcon className={styles.icon} />
                                            </Dropdown>
                                        )}
                                        <PinnedIcon onClick={pinnedPopupVisible.open} roomId={activeRoom.roomId} />
                                        <div
                                            className={styles.iconWrap}
                                            onClick={() => {
                                                extendedInfoVisible.toggle()
                                                if (searchBarVisible.isVisible) searchBarVisible.close()
                                            }}>
                                            <Icon icon="ICON_EXPEND" className={styles.icon} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <div className={styles.mainContent}>
                    <div className={styles.chatContent} ref={messagesRef} id="chatBody">
                        <div ref={firstItem} />
                        {messages.map((item, index) => {
                            return (
                                <Message
                                    key={item.messageId}
                                    message={item}
                                    showOptions={activeRoom.type !== Communication.RoomTypes.ClosedSupportHub}
                                    onEditMessage={onEditMessage}
                                    onReplyMessage={onReplyMessage}
                                    onDeleteMessage={onDeleteMessage}
                                    showTimestampSection
                                    prevMessageTime={index > 0 ? messages[index - 1].createdAt : undefined}
                                    highlightStudentMessage={
                                        departmentalUser?.userId === item.author.userId &&
                                        activeRoom.type === Communication.RoomTypes.Department
                                    }
                                    onClickReplyMessage={(_msg) => {
                                        scrollToMessageId(_msg.messageId)
                                    }}
                                />
                            )
                        })}
                        {messages.length === 0 && !fetchLatestMessageQuery.isFetching && (
                            <div className={styles.loadMoreText}>No messages found. Let's start a chat!</div>
                        )}
                        <div ref={lastItem} />
                        {fetchLatestMessageQuery.isFetching && (
                            <div className={styles.loading}>
                                <Spin />
                            </div>
                        )}
                    </div>
                </div>
                {renderTyping()}
                {!viewAs && activeRoom.type !== Communication.RoomTypes.ClosedSupportHub && (
                    <div className={styles.messageBox}>
                        <Compose
                            editMessage={editMessage}
                            replyMessage={replyMessage}
                            onlyContent
                            userList={roomMembers}
                        />
                    </div>
                )}
            </div>
            <SearchChat
                roomId={activeRoom.roomId}
                visible={searchBarVisible.isVisible}
                onHideSearch={searchBarVisible.close}
                onClickResult={navigateToMessage}
            />
            {!isSupportRoom && (
                <PopupPinnedMessages
                    roomId={activeRoom.roomId}
                    visible={pinnedPopupVisible.isVisible}
                    onClose={pinnedPopupVisible.close}
                    onClickPinnedMesage={(message) => {
                        setHighlightMessage(message)
                        pinnedPopupVisible.close()
                    }}
                />
            )}
            <ConfirmPopup
                isVisible={confirmChangeStatusPopup.isVisible}
                onClose={confirmChangeStatusPopup.close}
                onConfirm={changeSupportStatus}
                title="Confirm"
                description={confirmChangeStatusDescription}
                icon="QUESTION"
            />
        </div>
    )
}
