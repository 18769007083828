import {Timestamps} from "types/common"
import {Department} from "types/departments"
import {Campus} from "types/campus"
import {FundSource} from "types/fin-aid/fund-source"

export enum LedgerAccountType {
    Charges = "charges",
    Payments = "payments"
}

export const LedgerAccountTypeOptions = [
    {value: LedgerAccountType.Charges, label: "Charges"},
    {value: LedgerAccountType.Payments, label: "Payments"}
]

export enum LedgerAccountFundType {
    FederalFunds = "federal_funds",
    NonFederalFunds = "non_federal_funds",
    Other = "other",
    NotIncluded = "not_included"
}

export type LedgerAccount = Timestamps & {
    accountId: number
    name: string
    type: LedgerAccountType
    isInstitutionalCharge: boolean | 0 | 1
    is1098T: boolean | 0 | 1
    is1098TBox1: boolean | 0 | 1
    is1098TBox1Neg: boolean | 0 | 1
    is1098TBox4: boolean | 0 | 1
    is1098TBox4Neg: boolean | 0 | 1
    is1098TBox5: boolean | 0 | 1
    is1098TBox5Neg: boolean | 0 | 1
    is1098TBox6: boolean | 0 | 1
    is1098TBox6Neg: boolean | 0 | 1
    is1098TBox10: boolean | 0 | 1
    is1098TBox10Neg: boolean | 0 | 1
    isDisabled: boolean | 0 | 1
    isLock: boolean | 0 | 1
    effectiveDate?: string | null
    fundType?: LedgerAccountFundType | null
    fundSourceId?: number | null
    fundSource?: FundSource

    ninetyTenFedFundsPercentage?: number | null
    ninetyTenNonFedFundsPercentage?: number | null
    ninetyTenOtherPercentage?: number | null
    ninetyTenExcludePercentage?: number | null

    departments?: Department[] // populate
    campuses?: Campus.Campus[] // populate
}

export type GeneralPayment = Timestamps & {
    generalPaymentId: number
    position: number
    name: string
    isActive: boolean | 0 | 1
    isLock: boolean | 0 | 1
    accountId?: number | null
    account?: LedgerAccount // populate
}

export type TransactionItem = Timestamps & {
    itemId: number
    name: string
    position: number
}
