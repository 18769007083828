import React, {useCallback, useEffect, useState} from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {BaseNewFilter, ConfirmPopup, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {KlassappTableHOC} from "HOC"
import {AcademicInstructional} from "types/academicInstructional"
import styles from "./LessonAttendanceTable.module.css"
import {AttendanceDetailPopup} from "sections/academics/student/Terms/parts/CourseTab/parts/Attendance/parts"
import {Course} from "types/courses"
import {BaseButton, Icon} from "components"
import {AttendanceEventType, AttendanceStatus} from "types/attendance"
import {useVisible, UseVisibleArgs} from "hooks/useVisible"
import PopupVerifyAttendance from "../../../PopupVerifyAttendance"
import {cloneDeep, get, isEmpty} from "lodash"
import {FilterKey} from "types/filter"
import {convertTZ, getFullName, handleError, roundTwoDigits, toastError, toastSuccess} from "helpers"
import moment from "moment-timezone"
import {useDateTimeFormatters, useModel, useMutation} from "hooks"
import {useDebounce} from "react-use"
import {academicPlansService, attendanceService} from "services"
import {AddDropVariant} from "types/terms"
import {getDateOnly} from "sections/academics/instructional/common/utils"
import {Popover} from "antd"
import {AcademicPlans} from "types/academicPlans"

export enum LessonAttendanceTableType {
    All = "All",
    Present = "Present",
    Absent = "Absent"
}

type PageProps = {
    t: Function
    lessonStatus: string
    lessonId: number
    getListStudents: () => void
    students: AcademicInstructional.LessonStudent[]
    courseInfo?: Course.CourseTableItem
    timezone: string
    type: LessonAttendanceTableType
    actualStartedAt: string
    actualEndedAt: string
    timeFormat: string
    setIsLoadingStudent: (value: boolean) => void
    dateFormat: string
    addStudentPopup: UseVisibleArgs
}

type Props = KlassappTableProps & PageProps
const LessonAttendanceTable = (props: Props) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        data,
        timeFormat,
        actualStartedAt,
        actualEndedAt,
        timezone,
        lessonId,
        getListStudents,
        setIsLoadingStudent,
        orderField,
        isLoading,
        menuActions,
        lessonStatus,
        addStudentPopup,
        students,
        getCurrentData,
        onChangePage,
        onChangeRowPerPage,
        onChangeAllFields,
        onChangeFields,
        courseInfo,
        type
    } = props

    const deleteConfirmPopup = useVisible()
    const [selectedDeleteStudent, setSelectedDeleteStudent] = useState<{
        termId: number
        courseId: number
        scheduleId: number
        profileId: number
        lessonId: number
        calendarEventId: number
    }>()
    const model = useModel()
    const [tempFilter, setTempFilter] = useState({})
    const [filter, setFilter] = useState({})
    const {formatTime} = useDateTimeFormatters()
    const [selectedStudent, setSelectedStudent] = useState([])
    const storageData = model.getStorageFilter(FilterKey.LessonAttendancePresent)
    const [textSearch, setTextSearch] = useState(!isEmpty(storageData) ? storageData.textSearch : "")
    const [searchDebounce, setSearchDebounce] = useState(!isEmpty(storageData) ? storageData.textSearch : "")
    useDebounce(() => setSearchDebounce(textSearch), 400, [textSearch])
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const {courseId, attendanceTracking} = courseInfo
    const {t} = useTranslation(["studentAccount", "common"])
    const studentProfileId = get(selectedStudent, ["student", "profileId"])
    const confirmDeleteMakeupStudent = useMutation(
        async () => {
            if (selectedDeleteStudent) {
                await academicPlansService.deleteMakeupStudentInLesson({
                    deletedMakeupStudents: [
                        {
                            lessonId: selectedDeleteStudent.lessonId,
                            termId: selectedDeleteStudent.termId,
                            courseId: selectedDeleteStudent.courseId,
                            calendarEventId: selectedDeleteStudent.calendarEventId,
                            studentProfileId: selectedDeleteStudent.profileId,
                            scheduleId: selectedDeleteStudent.scheduleId
                        }
                    ]
                })
                getListStudents()
            }
            deleteConfirmPopup.close()
        },
        {
            mutationKey: [],
            onSuccess: () => toastSuccess(t("common:message.deleteSuccess")),
            onError: (error) => handleError(error)
        }
    )

    const getFields = () => {
        if (lessonStatus === AcademicInstructional.LessonStatus.Ended) {
            return ["ID", "Name", "CheckIn", "Checkout", "SchD", "Attd", "Miss", "Mkup", "Attn"]
        }
        return ["ID", "Name", "CheckIn", "Checkout", "In/Out"]
    }

    const getPageTitle = () => {
        return "Present"
    }

    const getColumns = () => {
        if (lessonStatus === AcademicInstructional.LessonStatus.Ended) {
            return [
                {
                    title: "ID",
                    field: "profileId",
                    style: {minWidth: "100px"}
                },
                {
                    title: "Name",
                    field: "fullName",
                    style: {minWidth: "200px"}
                },
                {
                    title: "CheckIn",
                    field: "checkInHtml",
                    style: {minWidth: "300px"}
                },
                {
                    title: "Checkout",
                    field: "checkOutHtml",
                    style: {minWidth: "120px"}
                },
                {
                    title: "SchD",
                    field: "scheduledHours",
                    style: {minWidth: "70px"}
                },
                {
                    title: "Attd",
                    field: "attendedHours",
                    style: {minWidth: "70px"}
                },
                {
                    title: "Miss",
                    field: "missedHours",
                    style: {minWidth: "70px"}
                },
                {
                    title: "Mkup",
                    field: "makeUpHours",
                    style: {minWidth: "70px"}
                },
                {
                    title: "Attn",
                    field: "attendHtml",
                    style: {minWidth: "70px"}
                }
            ]
        }
        return [
            {
                title: "ID",
                field: "profileId",
                style: {minWidth: "100px"}
            },
            {
                title: "Name",
                field: "fullName",
                style: {minWidth: "200px"}
            },
            {
                title: "CheckIn",
                field: "checkInHtml",
                style: {minWidth: "300px"}
            },
            {
                title: "Checkout",
                field: "checkOutHtml",
                style: {minWidth: "120px"}
            },
            {
                title: "In/Out",
                field: "inOut",
                style: {minWidth: "120px"}
            }
        ]
    }

    const renderName = (item) => {
        const isShowNote = !!item.events.find((item) => item.message && item.message !== "")
        const isDropBeforeAddDrop =
            item.student?.addDropVariant === AddDropVariant.Date
                ? moment(item.student?.droppedDate).isSameOrBefore(moment(item.student?.addDropDate))
                : moment(item.student?.droppedDate).isSameOrBefore(moment(item.student?.addDropHours))

        return (
            <div className={styles.name}>
                <div className={styles.nameStudent}>
                    <span>{getFullName(item.student)}</span>
                </div>
                {!!item.student?.isMakeup && (
                    <Popover placement="right" showArrow={false} content={renderMakeupInfo(item.student)}>
                        <div onClick={(e) => e.stopPropagation()} className={styles.iconNotes}>
                            <Icon icon="PLUS_PERSON" color="#666" />
                        </div>
                    </Popover>
                )}
                {isShowNote && (
                    <div className={styles.iconNotes}>
                        <Icon icon="CHAT_LINE" color="#666" />
                    </div>
                )}
                {!!item.student?.isDropped && (
                    <p className={styles.droppedText} style={{color: isDropBeforeAddDrop ? "#1890ff" : "red"}}>
                        DD (
                        {getDateOnly({
                            date: item.student?.droppedDate,
                            placeholder: true,
                            dateFormat: model.getUserDateFormat()
                        })}
                        )
                    </p>
                )}
                {!!item.student?.isLoa && (
                    <p className={styles.droppedText} style={{color: "#1890ff"}}>
                        LOA
                    </p>
                )}
            </div>
        )
    }

    const renderMakeupInfo = (makeupInfo: {
        makeupType: AcademicPlans.MakeupStudentType
        reason: string
        targetMakeupLesson?: {
            lessonName: string
            startsAt: string
            endAts: string
            termCode: string
            courseCode: string
            scheduleSuffix: string
        }
    }) => (
        <div onClick={(e) => e.preventDefault()}>
            <p className={styles.titleMakeupPopup}>This lesson is a Makeup lesson for:</p>
            <div className={styles.detailMakeupPopup}>
                {makeupInfo?.makeupType === AcademicPlans.MakeupStudentType.MakeupAttendance ? (
                    <>
                        <span className={styles.contentMakeupPopup}>
                            {makeupInfo.targetMakeupLesson?.lessonName} ({makeupInfo.targetMakeupLesson?.termCode}-
                            {makeupInfo.targetMakeupLesson?.scheduleSuffix})
                        </span>
                        <span className={styles.contentMakeupPopup}>
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserDateFormat())},{" "}
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserTimeFormat())} -{" "}
                            {moment(makeupInfo.targetMakeupLesson?.endAts).format(model.getUserTimeFormat())}
                        </span>
                    </>
                ) : (
                    <div className={styles.rowContentMakeup}>
                        <span className={styles.contentMakeupPopup}>Reason:</span>
                        <span className={styles.contentMakeupPopup}>{makeupInfo?.reason ?? ""}</span>
                    </div>
                )}
            </div>
        </div>
    )

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            let listAllData = cloneDeep(students).map((item: any, index: number) => {
                const attendanceId = item.attendanceId ? item.attendanceId : item.id
                item.attendanceStatus = item.status
                item.attendanceMessage = item.message
                item.isExcused = item.isExcusedAbsence
                item.attendanceId = attendanceId
                item.id = item.student.userId
                item.userId = item.student.userId
                item.profileId = item.student.profileId
                item.fullName = renderName(item)
                item.checkInHtml = renderCheckInHtml(item, index)
                item.checkOutHtml = renderCheckOutHtml(item)
                const actualMissedHours = roundTwoDigits(get(item, ["actualMissedHours"], 0) * 60)
                const missedHours = roundTwoDigits(get(item, ["missedHours"], 0) * 60)
                const actualAttendedHours = roundTwoDigits(get(item, ["actualAttendedHours"], 0) * 60)
                const attendedHours = roundTwoDigits(get(item, ["attendedHours"], 0) * 60)
                item.missedHours =
                    attendanceTracking === Course.AttendanceTrackingType.ClassAttendance
                        ? `${actualMissedHours} (${missedHours})`
                        : `${missedHours}`
                item.scheduledHours = `${roundTwoDigits(get(item, ["scheduledHours"], 0) * 60)}`
                item.attendedHours =
                    attendanceTracking === Course.AttendanceTrackingType.ClassAttendance
                        ? `${actualAttendedHours} (${attendedHours})`
                        : `${attendedHours}`
                item.makeUpHours = `${roundTwoDigits(get(item, ["makeUpHours"], 0) * 60)}`
                item.attendHtml = renderAttendHtml(item.status, item.isVerified)
                const lessonIsStarted = lessonStatus === AcademicInstructional.LessonStatus.Active
                const latestEvent = get(item.events, [item.events?.length - 1])
                if (lessonIsStarted) {
                    let typeButton: "in" | "out" = "in"
                    if (!latestEvent || latestEvent.eventType === AttendanceEventType.BreakStart) {
                        typeButton = "out"
                    }
                    item.inOut = renderButtonInOut(typeButton, item)
                }
                if (
                    !item?.events?.length ||
                    lessonIsStarted ||
                    item.attendanceStatus === AttendanceStatus.Irregular ||
                    item.isVerified === "yes" ||
                    !model.isStaffOrAdmin()
                ) {
                    item.isDisableCheckBox = true
                }
                return item
            })
            if (type === LessonAttendanceTableType.Absent) {
                listAllData = listAllData.filter((student) => {
                    if (attendanceTracking === Course.AttendanceTrackingType.ClassAttendance) {
                        const status = student.status === AttendanceStatus.Rejected
                        const isVerified = student.isVerified === "yes"
                        return status && isVerified
                    } else {
                        const checkInEvent = (student.events ?? []).find(
                            (event) => event.eventType === AcademicInstructional.AttendanceEventType.Checkin
                        )
                        if (!checkInEvent) {
                            return true
                        }
                        return false
                    }
                })
            } else if (type === LessonAttendanceTableType.Present) {
                listAllData = listAllData.filter((student) => {
                    if (attendanceTracking === Course.AttendanceTrackingType.ClassAttendance) {
                        const status = student.status === AttendanceStatus.Approved
                        const isVerified = student.isVerified === "yes"
                        return status && isVerified
                    } else {
                        const checkInEvent = (student.events ?? []).find(
                            (event) => event.eventType === AcademicInstructional.AttendanceEventType.Checkin
                        )
                        if (checkInEvent) {
                            return true
                        }
                        return false
                    }
                })
            }
            if (searchDebounce.length) {
                listAllData = listAllData.filter((student) => {
                    return (
                        getFullName(student.student).toLowerCase().includes(searchDebounce.toLowerCase()) ||
                        student.profileId.toString().includes(searchDebounce)
                    )
                })
            }
            dispatch({data: listAllData, total: listAllData.length})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (students.length) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonStatus, students, courseInfo, searchDebounce])

    const onCheckIn = async (profileId: number) => {
        try {
            let createdAt: moment.Moment = moment().tz(timezone, true).utc()
            await attendanceService.checkAttendanceManually([
                {
                    lessonId: lessonId,
                    profileId: profileId,
                    attendanceType: "manual",
                    message: "",
                    eventType: AttendanceEventType.Checkin,
                    status: "success",
                    createdAt: createdAt.format()
                }
            ])
        } catch (err) {
            handleError(err)
        }
    }

    const onInOut = (profileId: number, type: "in" | "out") => {
        try {
            const createdAt = moment().tz(timezone, true).utc()
            const params = [
                {
                    lessonId: lessonId,
                    profileId: profileId,
                    attendanceType: "manual",
                    message: "",
                    eventType: type === "in" ? AttendanceEventType.BreakStart : AttendanceEventType.BreakEnd,
                    status: "success",
                    createdAt: createdAt
                }
            ]
            return attendanceService.checkAttendanceManually(params)
        } catch (err) {
            handleError(err)
        }
    }

    const onPressInOut = async (e, item: {profileId: number; events: []}, typeButton: "in" | "out") => {
        e.stopPropagation()
        setIsLoadingStudent(true)
        const latestEvent = item.events[item.events.length - 1]
        if (!latestEvent) {
            await onCheckIn(item.profileId)
        } else {
            await onInOut(item.profileId, typeButton)
        }
        getListStudents()
    }

    const renderButtonInOut = (typeButton: "in" | "out", item) => {
        return (
            <div onClick={(e) => onPressInOut(e, item, typeButton)} className={styles.buttonOut}>
                {typeButton === "in" ? (
                    <div className={styles.actionButton} style={{backgroundColor: "#18A957"}}>
                        <Icon icon="IN_CLASS" color="white" />
                    </div>
                ) : (
                    <div className={styles.actionButton} style={{backgroundColor: "#DF1642"}}>
                        <Icon icon="OUT_CLASS" color="white" />
                    </div>
                )}
            </div>
        )
    }

    const renderAttendHtml = (status: AttendanceStatus, isVerified: "yes" | "no") => {
        if (isVerified === "yes" && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === "yes") {
            return (
                <div className={styles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }
    const renderCheckInHtml = (item: AcademicInstructional.LessonStudent, index: number) => {
        const checkInEvent = (item?.events ?? []).find(
            (event) =>
                event.eventType === AcademicInstructional.AttendanceEventType.Checkin && event.status === "success"
        )
        const checkOutEvent = (item?.events ?? []).find(
            (event) =>
                event.eventType === AcademicInstructional.AttendanceEventType.Checkout && event.status === "success"
        )
        const breakEvents = (item?.events ?? []).filter(
            (event) =>
                event.eventType !== AcademicInstructional.AttendanceEventType.Checkin &&
                event.eventType !== AcademicInstructional.AttendanceEventType.Checkout
        )

        return (
            <div className={styles.checkInContainer}>
                <div className={styles.checkInWrap}>
                    <div className={styles.checkInIconWrap}>
                        <Icon
                            icon="CHECKIN_FILL"
                            className={styles.checkInIcon}
                            color={
                                checkInEvent?.status === AcademicInstructional.CheckInStatus.Success
                                    ? "#1E90FF"
                                    : "#E6E6E6"
                            }
                        />
                    </div>
                    <span className={styles.checkInTime}>
                        {checkInEvent?.status === AcademicInstructional.CheckInStatus.Success
                            ? `${formatTime(convertTZ(new Date(checkInEvent.createdAt), timezone))} (${formatTime(
                                  convertTZ(new Date(checkInEvent.dateTimeRounded), timezone)
                              )})`
                            : ""}
                    </span>
                </div>
                <div className={styles.breakWrap}>
                    {breakEvents.map((event, index) => {
                        const isRenderCheckInLine = index === 0 && checkInEvent
                        const isRenderCheckOutLine =
                            event.eventType !== AcademicInstructional.AttendanceEventType.BreakStart &&
                            breakEvents.length &&
                            index === breakEvents.length - 1 &&
                            checkOutEvent
                        return (
                            <div key={event.id} className={styles.attendanceContainer}>
                                {isRenderCheckInLine ||
                                (index > 0 &&
                                    (breakEvents[index - 1].eventType ===
                                        AcademicInstructional.AttendanceEventType.BreakEnd ||
                                        breakEvents[index - 1].eventType ===
                                            AcademicInstructional.AttendanceEventType.AttendanceCheck)) ? (
                                    <div
                                        className={styles.progressLine}
                                        style={{
                                            right: "50%",
                                            left: isRenderCheckInLine ? -80 : "-50%"
                                        }}
                                    />
                                ) : null}
                                {isRenderCheckOutLine ? (
                                    <div
                                        className={styles.progressLine}
                                        style={{
                                            right: -70,
                                            left: "50%"
                                        }}
                                    />
                                ) : null}
                                <div className={styles.breakItem}>
                                    <div className={styles.checkInIconWrap}>
                                        {renderBreakIcon(event.eventType, event.status)}
                                    </div>
                                    <span className={styles.checkInTime}>
                                        {moment(convertTZ(new Date(event.createdAt), timezone)).format(timeFormat)}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {checkInEvent && checkOutEvent && breakEvents.length === 0 ? (
                    <div
                        className={styles.progressLine}
                        style={{
                            right: -70,
                            left: 30
                        }}
                    />
                ) : null}
            </div>
        )
    }

    const renderBreakIcon = (eventType: string, status: string) => {
        switch (eventType) {
            case AcademicInstructional.AttendanceEventType.BreakStart:
                return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.attendanceOutIcon} />
            case AcademicInstructional.AttendanceEventType.BreakEnd:
                return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.breakItem__icon} />
            default:
                return (
                    <Icon
                        icon="ATTENDANCE_IN"
                        color={status === "success" ? "#18A957" : "#E6E6E6"}
                        className={styles.breakItem__icon}
                    />
                )
        }
    }

    const renderCheckOutHtml = (item) => {
        const checkoutEvent = (item?.events ?? []).find(
            (event) => event.eventType === AcademicInstructional.AttendanceEventType.Checkout
        )
        return (
            <div className={styles.checkInWrap}>
                <div className={styles.checkInIconWrap}>
                    <Icon
                        icon="CHECKOUT_FILL"
                        className={styles.checkInIcon}
                        color={
                            checkoutEvent?.status === AcademicInstructional.CheckInStatus.Success
                                ? "#1E90FF"
                                : "#E6E6E6"
                        }
                    />
                </div>
                {checkoutEvent && checkoutEvent.status === AcademicInstructional.CheckInStatus.Success ? (
                    <span className={styles.checkInTime}>
                        {formatTime(convertTZ(new Date(checkoutEvent?.createdAt), timezone))} (
                        {formatTime(convertTZ(new Date(checkoutEvent?.dateTimeRounded), timezone))})
                    </span>
                ) : null}
            </div>
        )
    }

    const onClickRowItem = (data) => {
        if (model.isStaffOrAdmin()) {
            setIsShowPopup(true)
            setSelectedStudent({...data, attendedMinutes: `${roundTwoDigits((data.attendedHours ?? 0) * 60)}`})
        }
    }

    const onClosePopup = () => {
        setIsShowPopup(false)
        setSelectedStudent(undefined)
    }

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onShowConfirm = () => {
        setIsShowConfirmVerify(true)
    }

    const headerActions = () => {
        const actions: any = [
            {
                title: (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={styles.verifybtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
        return actions
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }

    const onApplyFilter = () => {
        setFilter(tempFilter)
    }

    const onClickClearFilter = () => {
        const defaultValue = {
            campus: null,
            instructor: null
        }
        setTempFilter(defaultValue)
        setFilter(defaultValue)
    }

    const onSearchInput = (text) => {
        model.updateStorageFilter(FilterKey.LessonAttendancePresent, {
            textSearch: text
        })
        setTextSearch(text)
    }

    const onClickDeleteMakeupStudent = useCallback(
        (data) => {
            if (!data.student?.isMakeup) {
                toastError("You can only delete makeup student!")
                return
            }
            setSelectedDeleteStudent(data)
            deleteConfirmPopup.open()
        },
        [deleteConfirmPopup]
    )

    const getMenuActions = () => {
        const menus = []
        if (model.isStaffOrAdmin()) {
            menus.push({
                title: "Delete",
                icon: "DELETE",
                action: onClickDeleteMakeupStudent,
                canShow: (data: any) => data.student?.isMakeup
            })
        }
        return menus
    }

    return (
        <div className={styles.wrapper}>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                searchValue={textSearch}
                renderRightFilter={() => (
                    <div className={styles.searchBox}>
                        <div className={styles.addStudentButton}>
                            <BaseButton title="Add Student" onClick={addStudentPopup.open} />
                        </div>
                    </div>
                )}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
            />
            <KlassappTableHeader
                page={page}
                isShowAction={!!data.find((item) => item.isChecked)}
                actions={headerActions()}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={getFields()}
                allFields={getFields()}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={getColumns()}
                data={data}
                menuActions={menuActions}
                isLoading={isLoading}
                fields={getFields()}
                allFields={getFields()}
                orderField={orderField}
                isShowCheckedColumn
                onUpdateRowData={onUpdateRowData}
                isShowCheckedColumnHeader={false}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
            />
            <AttendanceDetailPopup
                studentProfileIds={studentProfileId ? [studentProfileId] : []}
                courseIds={courseId ? [courseId] : []}
                getListAttendances={getListStudents}
                termIds={[]}
                actualStartedAt={actualStartedAt}
                actualEndedAt={actualEndedAt}
                courseInfo={courseInfo}
                selectedAttendance={selectedStudent}
                isShow={isShowPopup}
                onClose={onClosePopup}
            />
            <PopupVerifyAttendance
                data={data}
                courseInfo={courseInfo}
                isShowConfirmVerify={isShowConfirmVerify}
                onCancelConfirm={onCancelConfirm}
                refreshData={getListStudents}
                lessonId={lessonId}
            />
            <ConfirmPopup
                isVisible={deleteConfirmPopup.isVisible}
                title={t("common:action.confirm")}
                onClose={deleteConfirmPopup.close}
                onConfirm={confirmDeleteMakeupStudent.mutate}
                loading={confirmDeleteMakeupStudent.isLoading}
                description="Are you sure to delete this makeup student?"
                icon="DELETE"
            />
        </div>
    )
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(LessonAttendanceTable))
