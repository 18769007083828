import React, {useContext, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import styles from "./AssignChargesPopup.module.css"
import ChargesTable from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/ChargesTab/ChargesTable"
import {getPaymentAmountDue, StudentPayment} from "types/student-account/student-payment"
import {studentPaymentService} from "services"
import {Col, Divider, Row} from "antd"
import {BaseButton, SecondaryButton} from "components"
import {formatCurrency, getFullName, handleError, toastError} from "helpers"
import {StudentCharge} from "types/student-account/student-ledger"
import {useModel} from "hooks"
import moment from "moment/moment"
import cx from "classnames"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {sumBy} from "lodash"
import {FilterKey} from "types/filter"

type AssignChargePopupProps = {
    isShow: boolean
    onClose: () => void
    profileId: number
    paymentId?: number
}

export function AssignChargesPopup({isShow, onClose, profileId, paymentId}: AssignChargePopupProps) {
    const {t} = useTranslation(["studentAccount", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const {currencyExchangeRates} = useContext(StudentPaymentContext)
    const [isLoading, setLoading] = useState(false)
    const [payment, setPayment] = useState<StudentPayment | undefined>()
    const [selectedCharges, setSelectedCharges] = useState<StudentCharge[]>([])

    useEffect(() => {
        setSelectedCharges([])
        if (!paymentId) {
            setPayment(undefined)
            return
        }
        ;(async function loadStudentPayment() {
            try {
                const {
                    data: [studentPayment]
                } = await studentPaymentService.getPayments({
                    filter: {paymentId},
                    linkedEntities: true
                })
                setPayment(studentPayment)
            } catch (error) {
                setPayment(undefined)
            }
        })()
    }, [paymentId])

    const paymentAmountDue = useMemo(
        () => getPaymentAmountDue(payment, currencyExchangeRates),
        [payment, currencyExchangeRates]
    )

    const handleAssign = React.useCallback(async () => {
        for (const charge of selectedCharges) {
            if (!charge.paymentAmount) {
                toastError(t("payments.assignAmountRequired"))
                return
            }
        }

        const chargesAmount = sumBy(selectedCharges, "paymentAmount")
        if (chargesAmount > paymentAmountDue) {
            toastError(t("payments.assignAmountInvalid"))
            return
        }

        try {
            setLoading(true)
            await studentPaymentService.assignPaymentCharges(
                paymentId,
                selectedCharges.map((charge) => ({chargeId: charge.chargeId, amount: charge.paymentAmount}))
            )
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose, paymentId, paymentAmountDue, selectedCharges])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" leftIconColor="#fff" width="70vw">
            <div className={styles.popupWrapper}>
                <h3 className={styles.modalTitle}>{t("payments.assignCharges")}</h3>
                <Divider className="mt-10 mb-0" />

                <div className={styles.sectionTitle}>{t("payments.paymentDetails")}</div>

                {!!payment && (
                    <>
                        <div className={styles.profileContainer}>
                            <Row gutter={12} align="middle">
                                <Col>
                                    <div className={cx(styles.amountValue)}>
                                        <span className={styles.infoValue}>ORIG AMT </span>
                                        {formatCurrency(payment.amountInCents / 100, payment.currency, {
                                            currencyDisplay: "symbol"
                                        })}
                                    </div>
                                </Col>
                                <Col>
                                    <div className={cx(styles.amountValue)}>
                                        <span className={styles.infoValue}>AMT DUE </span>
                                        {formatCurrency(paymentAmountDue)}
                                    </div>
                                </Col>
                                <Col>{moment(payment.tranxDate).format(userDateFormat)}</Col>
                                <Col>
                                    <div className={cx(styles.profileInfo)}>
                                        <div className={styles.infoAvatar}>
                                            <img
                                                src={payment.studentProfile.photo?.[32] || "/image/DefaultAvatar.png"}
                                                alt={getFullName(payment.studentProfile)}
                                            />
                                        </div>
                                        <div className={styles.infoValue}>{getFullName(payment.studentProfile)}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className={styles.sectionTitle}>{t("payments.selectChargesToApply")}</div>

                        <ChargesTable
                            {...{
                                profileId,
                                assignPayment: payment,
                                selectedCharges,
                                setSelectedCharges,
                                filterKey: FilterKey.StudentAccountAssignPayments
                            }}
                        />
                    </>
                )}

                <Divider />

                <div className={styles.buttons}>
                    <div className={styles.button}>
                        <SecondaryButton onClick={onClose} title={t("common:action.cancel")} />
                    </div>
                    <div className={styles.button}>
                        <BaseButton
                            onClick={handleAssign}
                            title={t("payments.assign")}
                            loading={isLoading}
                            disabled={!selectedCharges.length}
                        />
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}
