import {DependencyStatus, StudentStatus} from "./students"
import {Campus} from "types/campus"
import {DegreeLevel} from "types/degreeLevel"
import {Major} from "types/major"
import {PaymentPlan} from "types/student-account/payment-plan"
import {StudentActivity} from "types/activity"
import {HousingPlan} from "types/fin-aid/fin-aid"
import {Timestamps} from "./common"

export namespace Auth {
    import ProgramLengthType = Major.ProgramLengthType

    export enum UserProfileType {
        Admin = "admin",
        Student = "student",
        Staff = "staff",
        Others = "others"
    }

    export enum StudentStateType {
        Student = "student",
        Prospect = "prospect"
    }

    export enum UserProfileState {
        Prospect = "prospect",
        Applicant = "applicant",
        Enrollment = "enrollment",
        Student = "student",
        Alumni = "alumni",
        Employee = "employee",
        ExEmployee = "ex-employee",
        Active = "active",
        Inactive = "inactive",
        Supervisor = "supervisor"
    }

    export const DEFAULT_ADMISSIONS_PROFILE_STATES = []
    export const DEFAULT_FINANCIAL_AID_PROFILE_STATES = [
        UserProfileState.Applicant,
        UserProfileState.Enrollment,
        UserProfileState.Student,
        UserProfileState.Alumni
    ]
    export const DEFAULT_STUDENT_ACCOUNTS_PROFILE_STATES = [
        UserProfileState.Applicant,
        UserProfileState.Enrollment,
        UserProfileState.Student,
        UserProfileState.Alumni
    ]
    export const DEFAULT_ACADEMICS_PROFILE_STATES = [
        UserProfileState.Applicant,
        UserProfileState.Enrollment,
        UserProfileState.Student,
        UserProfileState.Alumni
    ]
    export const DEFAULT_STUDENT_SERVICES_PROFILE_STATES = [
        UserProfileState.Applicant,
        UserProfileState.Enrollment,
        UserProfileState.Student,
        UserProfileState.Alumni
    ]
    export const DEFAULT_CAREER_SERVICES_PROFILE_STATES = [UserProfileState.Student, UserProfileState.Alumni]

    export enum ApplicationFee {
        Pay = "pay",
        NotPay = "not_pay",
        Wait = "wait"
    }

    export enum ModuleNavigation {
        Users = "Users",
        Admissions = "Admissions",
        Academics = "Academics",
        FinancialAid = "FinancialAid",
        AcademicAffairs = "AcademicAffairs",
        StudentServices = "StudentServices",
        CareerServices = "CareerServices",
        StudentAccount = "StudentAccount"
    }

    export enum StudentState {
        Prospect = "prospect",
        Applicant = "applicant",
        Enrollment = "enrollment",
        Student = "student",
        Alumni = "alumni",
        NeverAttended = "never_attended",
        TemporaryOut = "temporary_out",
        PermanentOut = "permanent_out"
    }

    export enum DegreeConferredStatus {
        NA = "na",
        Yes = "yes",
        No = "no"
    }

    export enum UserDetailTab {
        Personal = "personal",
        Profile = "profile",
        Credentials = "credentials",
        Modules = "modules",
        Contacts = "contacts",
        Directory = "directory",
        Tasks = "tasks",
        Documents = "documents",
        ActivityLog = "activity-log",
        FERPA = "ferpa"
    }

    export enum TimeFormat {
        Time_12 = "12 H",
        Time_24 = "24 H"
    }

    export enum ReasonForWithdrawal {
        Deceased = "deceased",
        Disabled = "disabled",
        MilitaryDuty = "military-duty",
        TransferredSameSchool = "transferred-same-school",
        TransferredOtherSchool = "transferred-other-school",
        EarlyWithdrawal = "early-withdrawal",
        Covid19 = "covid-19",
        Expelled = "expelled",
        Incarcerated = "incarcerated"
    }

    export type UserPhotoDetails = {
        32: string
        64: string
        128: string
        256: string
        512: string
        1024: string
        original: string
    }

    export type Advisor = {
        profileId: number
        contactProfileId: number
        isDefault: boolean
        studentStaffContactRoleId: number
    }

    export type AdvisorsListItemDetail = {
        userId: number
        photo: UserPhoto | null
        profileId: number
        customProfileId: string
        title: string
        firstName: string
        lastName: string
    }

    export type UserPhotoSize = keyof UserPhotoDetails

    export type UserShortDetails = {
        userId: number
        firstName: string
        lastName: string
        title?: string
        photo?: string
        email?: string
        birthDate?: string
        timeZone?: string
    }

    export type UserProfileShortDetails = {
        profileId: number
        firstName: string
        lastName: string
    }

    export type Email = {
        email: string
        isPrimary: number
        id?: number
        userId?: number
    }

    export type Phone = {
        phone: string
        isPrimary: number
        id?: number
        userId?: number
    }

    export type CurrentMajor = {
        id: number
        name: string
        description: string
        departmentId: null
        degreeLevelId: number
        createdByProfileId: number
        updatedByProfileId: number
        code: string
        createdAt: string
        updatedAt: string
        deletedAt: string
        sisId: number
        vDeleted: string
        cipCode: string
        departmentSubunitId: number
        programId: number
        isActive: boolean
        campusIds: number[]
        departmentDeanProfileIds: number[]
        versionIds: number[]
        departmentDeans: CreatedByProfile[]
        degreeLevel: any
    }

    export type CurrentMajorVersion = {
        id: number
        versionName: string
        versionCode: string
        effectiveDate: string
        type: string
        isFixedTermAndCourseSequence: boolean
        isRollingStartsProgram: boolean
        studentCount: number
        programId: number
        programLengthType: ProgramLengthType
        numberOfHoursPerWeek?: number | null
        numberOfLessons?: number | null
        numberOfMonths?: number | null
        numberOfTerms?: number | null
        numberOfWeeks?: number | null
        totalClockHours?: number | null
        createdBy: string
        updatedBy: string
        createdAt: string
        updatedAt: string
        deletedAt: string
        sisId: number
        vDeleted: string
        attendanceTrackingType?: string
        fullTimeHoursPerDay?: number
        hybridHoursPerDay?: number
        partTimeHoursPerDay?: number
    }

    export type AcademicTrack = {
        createdAt: string
        deletedAt: string
        description: string
        isLocked: number
        name: string
        trackId: number
        updatedAt: string
    }

    export type DepartmentSubunit = {
        subunitId: number
        name: string
        code: string
        departmentId: number
        department: {
            departmentId: number
            name: string
        }
    }

    export type Profile = {
        academicTrackId: number
        academicTracks: AcademicTrack[]
        academicTrack: string
        active: 1
        isAccessDisabled?: boolean
        isDefault?: boolean
        actualEndDate?: string
        sisId: number
        contractEndDate: string
        projectedEndDate: string
        firstDateOfClass: string
        midpointDate: string
        createdAt: string
        currentMajor: CurrentMajor | number
        currentMajorVersionId: CurrentMajorVersion | number
        currentTerm: any
        customProfileId?: string
        deletedAt: string
        endDate: string
        enrollmentStatus: string
        enrollmentType: string
        id: number
        ssn?: string | null
        isExternshipCoordinator: number
        isSiteSupervisor: number
        locations?: any[]
        startDate: string
        state: Auth.UserProfileState
        title: string
        type: string
        updatedAt: string
        sapStatus?: string
        phaseId?: number | null
        shiftId?: number | null
        userId: number
        userMigratedId: number
        enrollmentId: string
        campus?: any
        campuses?: any[]
        courses?: any[]
        departments?: any[]
        degreeLevel?: any
        departmentSubunits: DepartmentSubunit[]
        roleIds?: number[]
        roles?: any[]
        initialInquiryDate?: string
        applicationCompletedDate?: string
        acceptanceDate?: string
        lastDayOfAttendance?: string
        awardYear?: string
        payingMethods?: any[]
        dependencyStatus?: string
        startingTerm?: any
        startingTermId?: string
        applicantId?: string
        withdrawalDate?: string
        reasonForWithdrawal?: ReasonForWithdrawal
        cancelDate?: string
        reasonForCancellation?: string
        inquiryMessage?: string
        status?: StudentStatus
        numberOfDependents?: number
        clockStatus?: string
        academicStatsLastUpdated?: string
    }

    export type CreatedByProfile = {
        email: string
        firstName: string
        fullName: string
        lastName: string
        profileId: number
        title: string
        userId: number
    }

    export type UpdatedByProfile = {
        email: string
        firstName: string
        fullName: string
        lastName: string
        profileId: number
        title: string
        userId: number
    }

    export type UserProfile = {
        id: number
        type?: UserProfileType
        active?: number
        userId: number
        customUserId?: string
        address: string
        birthDate: string
        building: string
        campus: any
        city: string
        country: string
        createdAt: string
        createdByProfileId: number
        createdByProfile?: CreatedByProfile
        campuses?: Array<Campus.Campus>
        currentInitialsId: number
        currentSignatureId: number
        currentMajorVersionId?: number
        dateFormat: string
        dateTimeFormat?: string | null
        deletedAt: string
        diplomaDate: string
        emailNotifications: any
        smsNotifications: boolean
        emails: Email[]
        employer: string
        employerAddress: string
        employerPhone: string
        firstName: string
        gender: string
        genderPronoun?: string
        highSchoolAddress: string
        highSchoolCity: string
        highSchoolCountry: string
        highSchoolName: string
        highSchoolPostalCode: string
        highSchoolState: string
        highSchoolType: string
        howDidYouKnowAboutUs: string
        howDidYouKnowAboutUsId: number
        isMilitaryVeteran: boolean
        lastName: string
        maritalStatus: string
        medicalNotes: string
        middleName: string
        mobileNotifications: string
        phones: Phone[]
        // photo: JSON data
        photo: string
        position: string
        postalCode: string
        preferredTimeOfContact?: string[]
        preferredDaysOfContact?: string[]
        preferredContactMethod?: PreferredContactMethod | null
        profiles: Profile[]
        profileStatus?: number
        profileId: number
        race: string
        room: string
        ssn: string
        profileState?: Auth.StudentState
        state: string
        timeFormat: string
        timeZone: string
        tableView: string
        updatedAt: string
        updatedByProfileId?: number
        updatedByProfile?: UpdatedByProfile
        department?: any
        enrollmentType: string
        enrollmentId: string
        startDate: string
        contractEndDate?: string | null
        projectedEndDate?: string | null
        academicTracks: string
        currentTerm: string
        programVersion: string
        title: string
        isISIRConfirmed: number
        isHispanic: number
        filterMemory: number
        timezone?: string
    }

    export type LinkedProfileDetails = UserShortDetails & {
        id: number
        userId: number
        customUserId: string
        profileId: number
        type: UserProfileType
        fullName: string
        photo: UserPhoto | null
        currentMajor: number | null
        customProfileId: string
        programName: string
        nickNamePreferedName: string | null
    }

    export type UserPhoto = {
        32: string
        64: string
        128: string
        256: string
        512: string
        1024: string
        original: string
    }

    export type SignInParams = {
        email: string
        password: string
        slug: string
        deviceId?: string
        device?: "web"
    }

    export type SsoParams = {
        jwt: string
        slug: string
        payload: {}
        device?: "web"
    }

    export type OtpSignInParams = {
        email: string
        otp: string
        slug: string
    }

    export type SignInResponse = SignInNormal | SignIn2FA

    export type SignInNormal = {
        email: string
        slug: string
        token: string
        user: UserProfile
    }

    export type SignIn2FAOption = {
        type: "email" | "sms" | "app" | "authenticatorApp"
        email?: string
        phone?: string
    }

    export type SignIn2FA = {
        challenge: boolean
        deviceId: string
        challengeToken: string
        options: SignIn2FAOption[]
    }

    export type AutocompleteUserDetail = {
        id: number
        active: number
        firstName: string
        lastName: string
        middleName: string
        fullName: string
        photo: UserPhotoDetails
        profiles?: Array<{
            id: number
            type: UserProfileType
            campus?: Campus.Campus
            campuses?: Campus.Campus[]
        }>
    }

    export type DepartmentAdvisor = {
        userId: number
        firstName: string
        lastName: string
        fullName: string
        title: string
        email: string
        profileId: number
        contactProfileId: number
        isDefault: number
        studentStaffContactRoleId: number
    }

    export enum CheckListStatus {
        Empty = "empty",
        Inprogress = "inrprogress",
        Done = "done"
    }

    type LatestActivityShortDetails = {
        studentActivityId: number
        activityId: number
        profileId: number
        name: string
        activityDate: string
        createdAt: string
        updatedAt: string
        description: string
    }

    export type DepartmentStudent = {
        id: number | string
        userId: number
        firstName: string
        middleName: string
        lastName: string
        gender: string
        genderPronoun?: string
        birthDate: string
        race: string
        maritalStatus: string
        customUserId: any
        country: string
        address: string
        city: string
        degreeCredential: string
        studentAcademicStatus: string
        state: UserProfileState
        type: UserProfileType
        postalCode: string
        highSchoolType: string
        preferredTimeOfContact: string[]
        howDidYouKnowAboutUs: string
        howDidYouKnowAboutUsId: number
        photo: string | UserPhoto
        ssn?: string | null
        permanentResidentId?: string | null
        email: string
        phone: string
        profiles: any[]
        profileId: number
        profileState: Auth.StudentState
        profileStatus?: number
        roles?: Array<{roleId: number; roleName: string}>
        startDate?: string
        endDate?: string
        actualEndDate?: string
        lastDayOfAttendance?: string
        graduationDate?: string
        enrollmentStatus: string
        enrollmentId: string
        customProfileId?: string
        contractEndDate?: string
        projectedEndDate?: string
        firstDateOfClass?: string
        midpointDate?: string
        isFullyEnrolled: number
        actualContractEndDate?: string
        currentTerm?: any
        termId?: number
        termName?: string
        termStartDate?: string
        termEndDate?: string
        programId?: number
        programName?: string
        degreeLevel?: string | DegreeLevel.DegreeLevel
        degreeLevelId?: number
        programVersionId?: number
        programVersionName?: string
        programVersionTotalClockHour?: number
        programVersionType?: string
        programVersionNumberOfWeeks?: number
        programVersionNumberOfHoursPerWeek?: number
        academicTrack: null
        academicTrackId?: number | null
        academicsAdvisors: DepartmentAdvisor[]
        admissionAdvisors: DepartmentAdvisor[]
        finAidAdvisors: DepartmentAdvisor[]
        currentMajor?: Auth.CurrentMajor
        currentMajorVersionId?: number | Auth.CurrentMajorVersion
        startingTerm?: any
        startingTermId?: number
        startingTermName?: string
        startingTermStartDate?: string
        startingTermEndDate?: string
        campuses: string[] | Campus.Campus[]
        campusIds?: number[]
        admissionStatusId?: number | null
        admissionStatus: string
        admissionStage: string
        finAidStatusId?: number | null
        finAidStatus: string
        finAidStage: string
        admChecklistStatus?: CheckListStatus
        faChecklistStatus?: CheckListStatus
        isAcademicPlanLoaded: number
        acceptanceDate: string
        applicationCompletedDate: string
        cancelDate: string
        isDropped: boolean
        isCancelled: boolean
        withdrawalDate: string
        reasonForCancellation: string
        createdAt: string
        updatedAt: string
        inquiryMessage: string
        initialInquiryDate: string
        applicationFee: string
        admissionsLeadSourceId?: number
        leadTypeId?: number | null
        efc?: string
        dependencyStatus?: DependencyStatus
        enrollmentType?: string
        payingMethods?: any[]
        housingPlan?: HousingPlan | null
        isCalendarBased: boolean
        isCalendarBasedMultiCourse: boolean
        latestActivity?: LatestActivityShortDetails
        departmentDues?: StudentDepartmentDue[]
        statusId?: number | null
        status?: StudentStatus
        verificationStatusId?: number
        financialAidStatusId?: number
        numberOfDependents?: number
        attendedHours?: Date
        firstDayOfCourse?: string
        unreadActivityIds?: number[]
    } & Partial<{
        lastDayOfAttendance: string | null
        faReport?: {
            arBalance: number
            totalCharges: number
            institutionalCharges: number
            totalPayments: number
            totalTitleIVReceipts: number
            totalTitleIV: number
            totalDisbursed: number
            remainingBalance: number
            [key: string]: any
        }
        faTermId: number
        faTermName: string
        faStatusUpdatedAt: string
        faStatusUpdatedByProfileId: number
        contactProfileId: number
        tfrActivity: StudentActivity
        paymentPlan: PaymentPlan
        [key: string]: any
    }>

    export type StudentDepartmentDue = {
        profileId: number
        departmentId: number
        dueTime: string
        dueColor?: string | null
    }

    export type StudentStatisticsTerm = {
        gpa: number
        maxTimeframe: number
        attendancePercentage: number
        missedAttendanceHours: number
        makeUpHours: number
    }

    export type StudentStatistics = {
        profileId: number
        cgpa: number
        averageAttendancePercentage: number
        missedAttendanceHours: number
        maxTimeframe: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        makeUpHours: number
        totalHours: number
        totalHoursAttempted: number
        totalHoursCompleted: number
        totalHoursRemaining: number
        hoursScheduled?: number
        totalDistEdHours: number
        totalTransferHours: number
        totalProgramHours?: number
        sapStatus?: string
        phaseId?: number | null
        shiftId?: number | null
        terms: StudentStatisticsTerm[]
    }

    export type ModuleOption = {
        moduleId: number
        code: string
        name: string
        access: number
        parentId: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        children: ModuleOption
    }

    export type StudentLabelInfoItem = {
        english: string
        spanish: string
        isActive: boolean
    }

    export type StudentLabelInfo = {
        "academics.attendance_percentage": StudentLabelInfoItem
        "academics.cgpa": StudentLabelInfoItem
        "academics.gpa": StudentLabelInfoItem
        "academics.hours_to_make_up": StudentLabelInfoItem
        "academics.make_up_attendance_hours": StudentLabelInfoItem
        "academics.max_timeframe_percentage": StudentLabelInfoItem
        "academics.missed_attendance_hours": StudentLabelInfoItem
        "academics.distant_edu_hours": StudentLabelInfoItem
        "academics.total_hours": StudentLabelInfoItem
        "academics.attempted_hours": StudentLabelInfoItem
        "academics.completed_hours": StudentLabelInfoItem
        "academics.remaining_hours": StudentLabelInfoItem
        "academics.scheduled_hours": StudentLabelInfoItem
        "academics.total_progress": StudentLabelInfoItem
    }

    export type StudentDepartmentStatusDetails = {
        profileId: number
        statusId: number
        departmentId: number
    }

    export type PayingMethod = {
        payingMethodId: number
        name: string
    }

    export type Role = {
        roleId: number
        name: string
    }

    export type StudentDepartmentEnrollmentChangeProfileId = {
        profileId: number
        newProfileId: number
        departmentId: number
    }

    export enum PreferredContactMethod {
        Phone = "phone",
        Email = "email",
        SMS = "sms"
    }

    export type ListProfileWorkSchedulesPayload = {
        profileId: number
    }

    export type ProfileAvailabilitySlot = {
        id: number
        dayOfWeek: number
        startTime: string
        endTime: string
        createdAt: string
        updatedAt: string
        deletedAt: string
    } & Timestamps

    export type UpdateProfileAvailabilitySlot = Omit<ProfileAvailabilitySlot, "id" | keyof Timestamps> & {id?: number}

    export type UpdateProfileWorkSchedulesPayload = {
        profileId: number
        slots: UpdateProfileAvailabilitySlot[]
    }

    export type CourseStaffCanTeach = {
        courseId: number
        name: string
        code: string
        totalQuantity: number
        status: string
    }
}
