/* eslint-disable react-hooks/exhaustive-deps */
import {BaseButton, BaseInput, BaseLoading, BasePopup, BaseTextArea, Icon, SecondaryButton} from "components"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import styles from "./EditMakeupAttendance.module.css"
import moment from "moment"
import {useModel, useVisible, UploadedAttachment, useMakeupAttendanceSettings} from "hooks"
import {checkPermission, convertTZ, getFullName, handleError, roundTwoDigits, toastError} from "helpers"
import {academicPlansService, courseScheduleService, profileService} from "services"
import {Auth} from "types/auth"
import {SignaturePopup} from "uiKit"
import {Course} from "types/courses"
import {Schedule, TermDetails} from "types/terms"
import {AttendanceEvent, AttendanceEventStatus, AttendanceEventType} from "types/attendance"
import cx from "classnames"
import {cloneDeep} from "lodash"
import LessonTable from "../LessonTable"
import MissedAttendanceTable from "../MissedAttendanceTable"
import {QRScanPopup} from "../QRScan"
import {Permissions} from "@edular/permissions"
import {MakeupStudentType} from ".."
import MakeupFileSelect from "../MakeupFileSelect"
import {YesNo} from "types/common"
import {useAttachmentsUploader} from "hooks"
import {useMutation} from "@tanstack/react-query"
import {EVENT_COLOR} from "../constants"

export type MakeupAttendanceType = {
    tableType: MakeupStudentType
    academicMakeupStudentId: number
    studentName: string
    lessonId: number
    calendarEventId: number
    termId: number
    attendanceId: number
    scheduleId: number
    attendedMinutes: number
    makeUpMinutes: number
    actualAttendedMinutes: number
    actualAttendedHours: number
    missedHours?: number
    makeUpHours?: number
    reason?: string
    courseId: number
    studentProfileId: number
    customProfileId?: number
    date: string
    isRemoteAttendance: boolean
    studentCheckInSignatureImage: string
    studentCheckInSignatureUpdatedAt: string
    studentCheckOutSignatureImage: string
    studentCheckOutSignatureUpdatedAt: string
    instructorSignatureUpdatedAt: string
    instructorSignatureImage: string
    advisorSignatureUpdatedAt: string
    advisorSignatureImage: string
    advisorName: string
    instructorName: string
    timezone: string
    events: AttendanceEvent[]
    creditToDot: number | null
    appliedMinutes: number | null
    instructorPhoto: any
    instructorCustomUserId: string
}

type EditMakeupAttendanceProps = {
    isShow: boolean
    onClose: () => void
    selectedAttendance?: MakeupAttendanceType
    onRefreshData?: () => void
}
enum TableType {
    ApplyToLesson = "ApplyToLesson",
    ApplyToMissedAttendance = "ApplyToMissedAttendance"
}
const EditMakeupAttendance = (props: EditMakeupAttendanceProps) => {
    const model = useModel()
    const enableMakeupAttendanceSignOff = checkPermission(
        {
            staff: [Permissions.Staff.AcademicAffairs.MakeUpAttendance.MakeUpAttendanceSignOff.Edit]
        },
        model
    )
    const enableMakeupAttendanceVerification = checkPermission(
        {
            staff: [Permissions.Staff.AcademicAffairs.MakeUpAttendance.MakeUpAttendanceVerification.Edit]
        },
        model
    )
    const {isShow, onClose, onRefreshData, selectedAttendance} = props
    const [makeupAttendance, setMakeupAttendance] = useState<MakeupAttendanceType>()
    const currentMakeupAttendanceEvents = makeupAttendance?.events ?? []
    const attendanceIsCompleted = !!currentMakeupAttendanceEvents.find(
        (item) => item.eventType === AttendanceEventType.Checkout
    )
    const [notes, setNotes] = useState<string>("")
    const isIncomplete = selectedAttendance?.tableType === MakeupStudentType.Incomplete
    const signaturePopup = useVisible()
    const popupQrCode = useVisible()
    const advisorSignaturePopup = useVisible()
    const instructorSignaturePopup = useVisible()
    const [isFetchingMakeupData, setIsFetchingMakeupData] = useState(false)
    const [eventType, setEventType] = useState<AttendanceEventType>()
    const [creditToDot, setCreditToDot] = useState<string>(null)
    const [appliedMinutes, setAppliedMinutes] = useState<string>(null)
    const [schedules, setSchedules] = useState([])
    const [listLessons, setListLessons] = useState<
        Array<{
            scheduleId: number
            courseId: number
            termId: number
            id: number
            isChecked: boolean
            calendarEventId: number
        }>
    >([])
    const [filters, setFilters] = useState<{
        term: TermDetails
        course: Course.Course
        schedule: Schedule
    }>({
        term: undefined,
        course: undefined,
        schedule: undefined
    })
    const [tableType, setTableType] = useState<TableType>()
    const currentEvent = useRef<AttendanceEventType>(AttendanceEventType.Checkin)
    const [studentCheckOutSignature, setStudentCheckOutSignature] = useState<{
        signatureId: number
        imageUrl: string
        userId: number
    }>()
    const [selectedStudent, setSelectedStudent] = useState<Auth.UserProfile>()
    const [events, setEvents] = useState<AttendanceEvent[]>([])

    const checkin = events.find((event) => event.eventType === AttendanceEventType.Checkin)
    const checkout = events.find((event) => event.eventType === AttendanceEventType.Checkout)

    const [instructorSignature, setInstructorSignature] = useState<{
        instructorSignatureImage?: string
        instructorSignatureUpdatedAt?: string
        instructorName?: string
        instructorSignatureId?: number
    }>({
        instructorSignatureImage: undefined,
        instructorSignatureUpdatedAt: undefined,
        instructorName: undefined,
        instructorSignatureId: undefined
    })
    const [advisorSignature, setAdvisorSignature] = useState<{
        advisorSignatureImage?: string
        advisorSignatureUpdatedAt?: string
        advisorName?: string
        advisorSignatureId?: number
    }>({
        advisorSignatureImage: undefined,
        advisorSignatureUpdatedAt: undefined,
        advisorName: undefined,
        advisorSignatureId: undefined
    })
    const uploader = useAttachmentsUploader()
    const settings = useMakeupAttendanceSettings({
        enabled: isShow
    })
    const editMakeupAttendanceMutate = useMutation(async () => {
        if (!makeupAttendance) return

        const checkout = events.find((event) => event.eventType === AttendanceEventType.Checkout)
        if (
            settings.isMakeupDocumentAttachmentEnabled &&
            settings.isMakeupDocumentAttachmentMandatory &&
            checkout &&
            !uploader.attachments.length
        ) {
            toastError("Attachment is required. Please upload attachment before checkout")
            return
        }

        try {
            const updatedEvents = events.map((item) => {
                const createdAt = moment(item.createdAt)
                const startDate = moment(convertTZ(new Date(checkin.createdAt), makeupAttendance.timezone))

                createdAt.set({
                    month: startDate.month(),
                    date: startDate.date(),
                    year: startDate.year()
                })

                return {
                    id: item.id,
                    photoUrl: item.photoUrl,
                    eventType: item.eventType,
                    createdAt: createdAt.toISOString()
                }
            })

            const editMakeupAttendance = async (attachments?: UploadedAttachment[]) => {
                const payload = {
                    lessonId: makeupAttendance?.lessonId ?? null,
                    academicMakeupStudentId: makeupAttendance.academicMakeupStudentId,
                    events: updatedEvents.map((event) => ({
                        eventType: event.eventType,
                        status: AttendanceEventStatus.Success,
                        createdAt: event.createdAt,
                        photoUrl: event.photoUrl
                    })),
                    attachments,
                    instructorSignatureId: undefined,
                    advisorSignatureId: undefined,
                    studentCheckOutSignatureId: checkout ? studentCheckOutSignature?.signatureId : undefined,
                    studentProfileId: makeupAttendance.studentProfileId,
                    reason: notes ?? "",
                    creditToDot: creditToDot !== null && creditToDot !== "" ? creditToDot : null,
                    appliedMinutes: appliedMinutes !== "" ? appliedMinutes : null
                }
                if (!makeupAttendance?.instructorSignatureImage && instructorSignature.instructorSignatureId) {
                    payload.instructorSignatureId = instructorSignature.instructorSignatureId
                }
                if (!makeupAttendance?.advisorSignatureImage && advisorSignature.advisorSignatureId) {
                    payload.advisorSignatureId = advisorSignature.advisorSignatureId
                }

                await academicPlansService.editClassroomMakeupAttendance({
                    academicClassroomMakeupStudents: [payload]
                })

                onRefreshData?.()
                onClose()
            }

            if (uploader.attachments.length) {
                uploader.upload({
                    onSuccess: (attachments) => editMakeupAttendance(attachments),
                    onError: (err) => handleError(err)
                })
            } else {
                await editMakeupAttendance()
            }
        } catch (err) {
            handleError(err)
        }
    })

    const onSaveAdvisorSignature = useCallback(
        async (signature) => {
            setAdvisorSignature({
                advisorName: getFullName(model.user),
                advisorSignatureId: signature.signatureId,
                advisorSignatureImage: signature.imageUrl,
                advisorSignatureUpdatedAt: moment().toISOString()
            })
            advisorSignaturePopup.close()
        },
        [advisorSignaturePopup, model]
    )

    const onSaveInstructorSignature = useCallback(
        async (signature) => {
            if (signature.signatureId) {
                setInstructorSignature({
                    instructorName: getFullName(model.user),
                    instructorSignatureId: signature.signatureId,
                    instructorSignatureImage: signature.imageUrl,
                    instructorSignatureUpdatedAt: moment().toISOString()
                })
            }
            instructorSignaturePopup.close()
        },
        [instructorSignaturePopup, model]
    )

    const onSaveSignature = useCallback(
        (signature) => {
            signaturePopup.close()
            if (!events.length && currentEvent.current === AttendanceEventType.Checkin) {
                events.push({
                    createdAt: moment().toISOString(),
                    status: AttendanceEventStatus.Success,
                    eventType: AttendanceEventType.Checkin,
                    location: ""
                })
            } else if (currentEvent.current === AttendanceEventType.Checkout) {
                const checkinDate = moment(checkin.createdAt)
                const createdAt = moment()
                createdAt.set({
                    year: checkinDate.get("year"),
                    month: checkinDate.get("month"),
                    date: checkinDate.get("date")
                })
                events.push({
                    createdAt: createdAt.toISOString(),
                    status: AttendanceEventStatus.Success,
                    eventType: AttendanceEventType.Checkout,
                    location: ""
                })
            } else if (currentEvent.current === AttendanceEventType.BreakStart) {
                const latestEvent = events[events.length - 1]
                const checkinDate = moment(checkin.createdAt)
                const createdAt = moment()
                createdAt.set({
                    year: checkinDate.get("year"),
                    month: checkinDate.get("month"),
                    date: checkinDate.get("date")
                })
                if (latestEvent?.eventType === AttendanceEventType.BreakStart) {
                    events.push({
                        createdAt: createdAt.toISOString(),
                        status: AttendanceEventStatus.Success,
                        eventType: AttendanceEventType.BreakEnd,
                        location: ""
                    })
                } else if (
                    [AttendanceEventType.BreakEnd, AttendanceEventType.Checkin].includes(latestEvent?.eventType)
                ) {
                    events.push({
                        createdAt: createdAt.toISOString(),
                        status: AttendanceEventStatus.Success,
                        eventType: AttendanceEventType.BreakStart,
                        location: ""
                    })
                }
            }
            const checkout = events.find((event) => event.eventType === AttendanceEventType.Checkout)
            if (checkout) {
                setStudentCheckOutSignature(signature)
            }
            setEvents([...events])
        },
        [signaturePopup, events, checkin]
    )

    const onCheckin = useCallback(() => {
        currentEvent.current = AttendanceEventType.Checkin
        setEventType(AttendanceEventType.Checkin)
        signaturePopup.open()
    }, [signaturePopup])

    const onCheckout = useCallback(() => {
        currentEvent.current = AttendanceEventType.Checkout
        setEventType(AttendanceEventType.Checkout)
        signaturePopup.open()
    }, [signaturePopup])

    const onBreak = useCallback(() => {
        currentEvent.current = AttendanceEventType.BreakStart
        setEventType(AttendanceEventType.BreakStart)
        signaturePopup.open()
    }, [signaturePopup])

    const onAdvisorSign = useCallback(() => {
        if (!enableMakeupAttendanceVerification) {
            return toastError(`You don't have permissions to sign`)
        }
        advisorSignaturePopup.open()
    }, [advisorSignaturePopup, enableMakeupAttendanceVerification])

    const onInstructorSign = useCallback(() => {
        if (!enableMakeupAttendanceSignOff) {
            return toastError(`You don't have permissions to sign`)
        }
        instructorSignaturePopup.open()
    }, [instructorSignaturePopup, enableMakeupAttendanceSignOff])

    const onSave = useCallback(editMakeupAttendanceMutate.mutate, [editMakeupAttendanceMutate])

    const renderEvents = () => {
        return (
            <div className={styles.eventsContainer}>
                {events.map((event, index) => (
                    <>
                        <div className={styles.event}>
                            {event.eventType === AttendanceEventType.Checkin ? (
                                <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={EVENT_COLOR.CHECK_IN} />
                            ) : null}
                            {event.eventType === AttendanceEventType.Checkout ? (
                                <Icon icon="CHECKOUT_FILL" className={styles.eventIcon} color={EVENT_COLOR.CHECK_OUT} />
                            ) : null}
                            {event.eventType === AttendanceEventType.BreakStart ? (
                                <Icon
                                    icon="ATTENDANCE_OUT"
                                    className={styles.eventIcon}
                                    color={EVENT_COLOR.BREAK_START}
                                />
                            ) : null}
                            {event.eventType === AttendanceEventType.BreakEnd ? (
                                <Icon icon="ATTENDANCE_IN" className={styles.eventIcon} color={EVENT_COLOR.BREAK_END} />
                            ) : null}
                            <span className={styles.eventTime}>
                                {moment(convertTZ(new Date(event.createdAt), makeupAttendance?.timezone)).format(
                                    model.getUserTimeFormat()
                                )}
                            </span>
                        </div>
                        {index !== events?.length - 1 ? <div className={styles.eventSeparator} /> : null}
                    </>
                ))}
            </div>
        )
    }

    const onCancel = useCallback(() => {
        onClose()
    }, [onClose])

    const getListStudent = async () => {
        if (selectedAttendance) {
            try {
                let {data} = await profileService.getAll({
                    linkedObjects: true,
                    filter: {
                        type: ["student"],
                        id: [selectedAttendance.studentProfileId],
                        campuses: []
                    },
                    range: {
                        limit: 1,
                        offset: 0
                    }
                })
                data = data.map((student) => ({
                    ...student,
                    photo:
                        typeof student.photo === "string" && student.photo?.length
                            ? JSON.parse(student.photo)
                            : student.photo,
                    profileId: student.id
                }))
                setSelectedStudent(data[0])
            } catch (e) {
                handleError(e)
            }
        }
    }

    const getMakeupAttendanceDetail = async () => {
        if (selectedAttendance.academicMakeupStudentId) {
            setIsFetchingMakeupData(true)
            try {
                const {data, total} = await academicPlansService.getListOfClassroomMakeupAttendance({
                    filter: {
                        academicMakeupStudentIds: [selectedAttendance.academicMakeupStudentId],
                        linkedObjects: true
                    }
                })
                const makeupAttendance = data[0] ?? {}
                uploader.setAttachments(
                    (makeupAttendance.attachments ?? []).map((attachment) => ({
                        attachmentUrl: attachment.attachmentUrl,
                        presignedUrl: attachment.presignedUrl,
                        documentName: attachment.name,
                        file: {
                            name: attachment.name
                        }
                    }))
                )
                setEvents(cloneDeep(makeupAttendance.attendance?.events ?? []))
                setCreditToDot(makeupAttendance.creditToDot?.toString() ?? null)
                setAppliedMinutes(makeupAttendance.appliedMinutes?.toString() ?? null)
                setInstructorSignature({
                    instructorSignatureImage: makeupAttendance.instructorSignatureImage,
                    instructorName: makeupAttendance.instructorName,
                    instructorSignatureUpdatedAt: makeupAttendance.instructorSignatureUpdatedAt
                })
                setAdvisorSignature({
                    advisorSignatureImage: makeupAttendance.advisorSignatureImage,
                    advisorName: makeupAttendance.advisorName,
                    advisorSignatureUpdatedAt: makeupAttendance.advisorSignatureUpdatedAt
                })
                setNotes(makeupAttendance.reason ?? "")
                const actualAttendedHours = makeupAttendance.attendance?.actualAttendedHours ?? 0
                const attendedHours = makeupAttendance.attendance?.attendedHours ?? 0
                const makeUpHours = makeupAttendance.attendance?.makeUpHours ?? 0
                const missedHours = makeupAttendance.attendance?.missedHours ?? 0
                setMakeupAttendance({
                    ...makeupAttendance,
                    studentName: `${makeupAttendance.firstName} ${makeupAttendance.lastName}`,
                    events: makeupAttendance.attendance?.events ?? [],
                    attendanceId: makeupAttendance.attendance?.attendanceId,
                    missedHours: roundTwoDigits(missedHours),
                    makeUpHours: roundTwoDigits(makeUpHours),
                    makeUpMinutes: roundTwoDigits(makeUpHours * 60),
                    attendedMinutes: roundTwoDigits(attendedHours * 60),
                    actualAttendedHours: roundTwoDigits(actualAttendedHours),
                    actualAttendedMinutes: roundTwoDigits(actualAttendedHours * 60),
                    customProfileId: makeupAttendance.customProfileId ?? ""
                })
            } catch (err) {
                handleError(err)
            } finally {
                setIsFetchingMakeupData(false)
            }
        }
    }

    useEffect(() => {
        if (isShow && selectedAttendance) {
            getListStudent()
            getMakeupAttendanceDetail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttendance, isShow])

    const renderUserInfo = useCallback(
        (user: Auth.UserProfile & {customProfileId?: number; name?: string}, isStudent: boolean = false) => {
            let photo = "/image/DefaultAvatar.png"
            if (user?.photo) {
                photo = user.photo["256"]
            }
            return (
                <>
                    <p className={styles.label} style={isStudent ? {marginTop: 40} : {}}>
                        {isStudent ? "STUDENT" : "INSTRUCTOR"}
                    </p>
                    <div className={styles.instructorBox}>
                        <div className={styles.avatar}>
                            <img src={photo} alt="instructorAvt" />
                        </div>
                        <div className={styles.instructorDetail}>
                            <div className={styles.nameBox} style={{marginTop: 0}}>
                                <span className={styles.instructorName}>
                                    {user?.name ? user.name : getFullName(user)}
                                </span>
                                <div className={styles.eventBox}>
                                    {!!checkin && isStudent ? (
                                        <div className={styles.checkInBox}>
                                            {checkin.presignedPhotoUrl?.length ? (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={checkin.presignedPhotoUrl}
                                                    style={{marginLeft: 10, marginRight: 5, fontSize: 16}}>
                                                    Photo
                                                </a>
                                            ) : null}
                                            <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={"#18a957"} />
                                            <span className={styles.eventTime} style={{marginRight: 4}}>
                                                Checkin:
                                            </span>
                                            <span className={styles.eventTime}>
                                                {moment(
                                                    convertTZ(new Date(checkin.createdAt), makeupAttendance?.timezone)
                                                ).format(model.getUserTimeFormat())}
                                            </span>
                                            <div className={styles.statusWrap}>
                                                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                                                    <img
                                                        src={makeupAttendance?.studentCheckInSignatureImage}
                                                        alt="signature"
                                                        className={styles.signature__img}
                                                    />
                                                </div>
                                                <div className={styles.dateInfo}>
                                                    <span className={styles.dateInfo__text}>
                                                        {moment(
                                                            convertTZ(
                                                                makeupAttendance?.studentCheckInSignatureUpdatedAt ??
                                                                    checkin.createdAt,
                                                                makeupAttendance?.timezone
                                                            )
                                                        ).format(model.getUserDateTimeFormat())}
                                                    </span>
                                                    <span className={styles.dateInfo__text}>
                                                        {makeupAttendance?.studentName}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : instructorSignature?.instructorSignatureImage ? (
                                        <div className={styles.checkInBox}>
                                            <div className={styles.statusWrap}>
                                                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                                                    <img
                                                        src={instructorSignature?.instructorSignatureImage}
                                                        alt="signature"
                                                        className={styles.signature__img}
                                                    />
                                                </div>
                                                <div className={styles.dateInfo}>
                                                    <span className={styles.dateInfo__text}>
                                                        {moment(
                                                            convertTZ(
                                                                instructorSignature?.instructorSignatureUpdatedAt,
                                                                makeupAttendance?.timezone
                                                            )
                                                        ).format(model.getUserDateTimeFormat())}
                                                    </span>
                                                    <span className={styles.dateInfo__text}>
                                                        {instructorSignature?.instructorName}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {!!checkout && isStudent ? (
                                        <div className={styles.checkInBox}>
                                            {checkout.presignedPhotoUrl?.length ? (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={checkout.presignedPhotoUrl}
                                                    style={{marginLeft: 10, marginRight: 5, fontSize: 16}}>
                                                    Photo
                                                </a>
                                            ) : null}
                                            <Icon icon="CHECKOUT_FILL" className={styles.eventIcon} color={"#DF1642"} />
                                            <span className={styles.eventTime} style={{marginRight: 4}}>
                                                Checkout:
                                            </span>
                                            <span className={styles.eventTime}>
                                                {moment(
                                                    convertTZ(new Date(checkout.createdAt), makeupAttendance?.timezone)
                                                ).format(model.getUserTimeFormat())}
                                            </span>
                                            <div className={styles.statusWrap}>
                                                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                                                    <img
                                                        src={
                                                            makeupAttendance?.studentCheckOutSignatureImage
                                                                ? makeupAttendance?.studentCheckOutSignatureImage
                                                                : studentCheckOutSignature?.imageUrl
                                                        }
                                                        alt="signature"
                                                        className={styles.signature__img}
                                                    />
                                                </div>
                                                <div className={styles.dateInfo}>
                                                    <span className={styles.dateInfo__text}>
                                                        {moment(
                                                            convertTZ(
                                                                makeupAttendance?.studentCheckOutSignatureUpdatedAt ??
                                                                    checkout.createdAt,
                                                                makeupAttendance?.timezone
                                                            )
                                                        ).format(model.getUserDateTimeFormat())}
                                                    </span>
                                                    <span className={styles.dateInfo__text}>
                                                        {makeupAttendance?.studentName}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {((checkin && checkout) || isIncomplete) &&
                                    !isStudent &&
                                    !instructorSignature?.instructorSignatureImage &&
                                    !makeupAttendance?.isRemoteAttendance &&
                                    model.isStaffOrAdmin() ? (
                                        <BaseButton
                                            style={{marginLeft: 8}}
                                            title={"INSTRUCTOR SIGN"}
                                            onClick={onInstructorSign}
                                            loading={editMakeupAttendanceMutate.isLoading}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className={cx(styles.instructorInfo, {
                                    [styles.line]: isStudent
                                })}>
                                <div className={styles.nameBox} style={!isStudent ? {flex: "none"} : {}}>
                                    <span className={styles.labelUserId}>User ID</span>
                                    <span className={styles.valueUserId}>{user?.customUserId}</span>
                                </div>
                                {isStudent ? (
                                    <>
                                        <div className={styles.nameBox}>
                                            <span className={styles.labelUserId}>Enrollment ID:</span>
                                            <span className={styles.valueUserId}>{user?.customProfileId}</span>
                                        </div>
                                        <div className={styles.nameBox}>
                                            <span className={styles.labelUserId}>Remote:</span>
                                            <span className={styles.valueUserId}>
                                                {(makeupAttendance?.isRemoteAttendance
                                                    ? YesNo.Yes
                                                    : YesNo.No
                                                ).toUpperCase()}
                                            </span>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )
        },
        [
            checkin,
            checkout,
            editMakeupAttendanceMutate,
            model,
            onInstructorSign,
            makeupAttendance,
            studentCheckOutSignature,
            instructorSignature,
            isIncomplete
        ]
    )

    const renderTitleSignature = useMemo(() => {
        return (
            <div className={styles.titleSignContainer}>
                <Icon icon={"SIGN_MAKEUP_ATTENDANCE"} />
                <span className={styles.titleSign}>STUDENT SIGNATURE</span>
                <span className={styles.subTitleSign}>
                    TO{" "}
                    {eventType === AttendanceEventType.Checkin
                        ? "CHECK IN"
                        : eventType === AttendanceEventType.Checkout
                        ? "CHECK OUT"
                        : "BREAK"}
                </span>
            </div>
        )
    }, [eventType])

    const renderTitleAdvisorSignature = useMemo(() => {
        return (
            <div className={styles.titleSignContainer}>
                <Icon icon={"SIGN_MAKEUP_ATTENDANCE"} />
                <span className={styles.titleSign}>ACADEMIC AFFAIRS</span>
                <span className={styles.subTitleSign}>VERIFY</span>
            </div>
        )
    }, [])

    const renderTitleInstructorSignature = useMemo(() => {
        return (
            <div className={styles.titleSignContainer}>
                <Icon icon={"SIGN_MAKEUP_ATTENDANCE"} />
                <span className={styles.titleSign}>INSTRUCTOR</span>
                <span className={styles.subTitleSign}>CONFIRM</span>
            </div>
        )
    }, [])

    const getSchedules = useCallback(async () => {
        try {
            const {data} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: filters.term?.id,
                    course_id: filters.course.courseId
                }
            })
            setSchedules(data)
        } catch (e) {
            handleError(e)
        }
    }, [filters.term, filters.course])

    useEffect(() => {
        if (filters.course) {
            getSchedules()
        }
    }, [getSchedules, filters.course])

    const attendedMinutes = useMemo(() => {
        let breakEventsStart = events.filter((item) => item.eventType === AttendanceEventType.BreakStart)
        let breakEventsEnd = events.filter((item) => item.eventType === AttendanceEventType.BreakEnd)

        let attendedMinutes = 0
        let attendanceEvents: Array<AttendanceEvent> = [...breakEventsStart, ...breakEventsEnd]
        if (checkin) {
            attendanceEvents.push(checkout)
        }
        if (checkout) {
            attendanceEvents.push(checkin)
        }
        attendanceEvents = attendanceEvents.sort(
            (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
        )

        for (let i = 0; i < attendanceEvents.length; i++) {
            const event = attendanceEvents[i]
            if (
                event &&
                (event.eventType === AttendanceEventType.Checkin || event.eventType === AttendanceEventType.BreakEnd)
            ) {
                if (i + 1 < attendanceEvents.length) {
                    const nextEvent = attendanceEvents[i + 1]
                    if (
                        nextEvent &&
                        (nextEvent.eventType === AttendanceEventType.Checkout ||
                            nextEvent.eventType === AttendanceEventType.BreakEnd ||
                            nextEvent.eventType === AttendanceEventType.BreakStart)
                    ) {
                        const start = moment(event.createdAt)
                        const end = moment(nextEvent.createdAt)
                        start.set({
                            seconds: 0,
                            milliseconds: 0
                        })
                        end.set({
                            seconds: 0,
                            milliseconds: 0
                        })
                        const durationBreakEvent = moment.duration(start.diff(end))
                        const minutesDurationBreakEvent = Math.abs(durationBreakEvent.asMinutes())
                        attendedMinutes = roundTwoDigits(Math.max(attendedMinutes + minutesDurationBreakEvent, 0))
                    }
                }
            }
        }
        return attendedMinutes
    }, [events, checkin, checkout])

    const renderMakeupMinutes = useMemo(() => {
        let makeupMinutes = 0
        if (makeupAttendance?.attendedMinutes > 0 && attendanceIsCompleted) {
            makeupMinutes = roundTwoDigits(makeupAttendance.attendedMinutes)
        } else {
            makeupMinutes = roundTwoDigits(attendedMinutes)
        }
        return (
            <div className={styles.eventHours}>
                <span className={styles.numberOfAttendedMinutes}>Makeup minutes: </span>
                <span className={styles.numberOfAttendedMinutes}>{makeupMinutes}</span>
            </div>
        )
    }, [makeupAttendance, attendedMinutes, attendanceIsCompleted])

    return (
        <>
            <BasePopup isShow={isShow} onClose={onClose} width="90vw" leftIcon="PERSON_ADD">
                <div className={styles.wrap}>
                    <p className={styles.title}>Student make up</p>
                    <div className={styles.header}>
                        <div className={styles.date}>
                            <Icon icon="CALENDAR_ATTENDANCE" color="#62B1FF" className={styles.calendarIcon} />
                            <p className={styles.textDate}>
                                {makeupAttendance
                                    ? moment(
                                          convertTZ(new Date(makeupAttendance.date), makeupAttendance.timezone)
                                      ).format(model.getUserDateFormat())
                                    : ""}
                            </p>
                        </div>
                    </div>
                    {renderUserInfo(
                        makeupAttendance?.instructorSignatureImage
                            ? ({
                                  name: makeupAttendance.instructorName,
                                  photo: makeupAttendance.instructorPhoto,
                                  customUserId: makeupAttendance.instructorCustomUserId
                              } as any)
                            : model.user
                    )}

                    {renderUserInfo(selectedStudent, true)}
                    <div className={styles.checkContainer}>
                        <span className={styles.label}>CHECKS</span>
                    </div>
                    <div className={styles.eventContainer}>
                        {!events.length ? (
                            <span className={styles.labelCheckLesson}>Check into a lesson</span>
                        ) : (
                            renderEvents()
                        )}
                        <div className={styles.eventAction}>
                            {!checkout && !isIncomplete ? (
                                <div onClick={popupQrCode.open} className={styles.scanQrButton}>
                                    <Icon icon="ATTENDANCE_QR_CODE" />
                                </div>
                            ) : null}
                            {!checkin && model.isStaffOrAdmin() && <BaseButton title={"CHECKIN"} onClick={onCheckin} />}
                            {checkin && !checkout && !isIncomplete && model.isStaffOrAdmin() && (
                                <BaseButton title={"BREAK"} onClick={onBreak} />
                            )}
                            {checkin && !checkout && !isIncomplete && model.isStaffOrAdmin() && (
                                <BaseButton title={"CHECKOUT"} onClick={onCheckout} />
                            )}
                            {(checkin && checkout) || isIncomplete ? (
                                <div className={styles.eventHours}>
                                    <span className={styles.numberOfAttendedMinutes}>Actual minutes: </span>
                                    <span className={styles.numberOfAttendedMinutes}>
                                        {attendanceIsCompleted
                                            ? roundTwoDigits(makeupAttendance.actualAttendedMinutes)
                                            : roundTwoDigits(attendedMinutes)}
                                    </span>
                                </div>
                            ) : null}
                            {(checkin && checkout) || isIncomplete ? renderMakeupMinutes : null}
                        </div>
                    </div>
                    {settings.isMakeupDocumentAttachmentEnabled ? (
                        <MakeupFileSelect
                            attachments={uploader.attachments}
                            onRemoveFile={uploader.removeAttachment}
                            onSelectFiles={uploader.onSelectFiles}
                            editable
                        />
                    ) : null}
                    <div className={styles.checkContainer}>
                        <span className={styles.label}>Notes</span>
                    </div>
                    <BaseTextArea
                        value={notes}
                        readOnly={!model.isStaffOrAdmin()}
                        onChange={(text) => {
                            setNotes(text)
                        }}
                        placeholder={"Notes"}
                        style={{
                            width: "100%"
                        }}
                    />
                    {makeupAttendance?.instructorSignatureImage || makeupAttendance?.isRemoteAttendance ? (
                        <>
                            <span className={styles.applyToLessons} style={{marginTop: 14, marginBottom: 8}}>
                                CREDIT TO DOT
                            </span>
                            <div className={styles.creditToDot}>
                                <BaseInput
                                    readOnly={!model.isStaffOrAdmin()}
                                    onChange={setCreditToDot}
                                    value={creditToDot}
                                />
                                {advisorSignature?.advisorSignatureImage ? (
                                    <div className={styles.statusWrap}>
                                        <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                                            <img
                                                src={advisorSignature?.advisorSignatureImage}
                                                alt="signature"
                                                className={styles.signature__img}
                                            />
                                        </div>
                                        <div className={styles.dateInfo}>
                                            <span className={styles.dateInfo__text}>
                                                {moment(
                                                    convertTZ(
                                                        advisorSignature?.advisorSignatureUpdatedAt,
                                                        makeupAttendance?.timezone
                                                    )
                                                ).format(model.getUserDateTimeFormat())}
                                            </span>
                                            <span className={styles.dateInfo__text}>
                                                {advisorSignature?.advisorName}
                                            </span>
                                        </div>
                                    </div>
                                ) : !makeupAttendance?.advisorSignatureImage && model.isStaffOrAdmin() ? (
                                    <BaseButton
                                        title={"ADVISOR SIGN"}
                                        onClick={onAdvisorSign}
                                        loading={editMakeupAttendanceMutate.isLoading}
                                    />
                                ) : null}
                            </div>
                            <span className={styles.applyToLessons} style={{marginTop: 14, marginBottom: 8}}>
                                APPLIED MINUTES
                            </span>
                            <div className={styles.creditToDot}>
                                <BaseInput
                                    readOnly={!model.isStaffOrAdmin()}
                                    onChange={setAppliedMinutes}
                                    value={appliedMinutes}
                                />
                            </div>
                        </>
                    ) : null}

                    {/* TODO: Implement Apply to Lesson and Missed Attendance */}
                    {/* <div className={styles.titleTable}>
                        <div className={styles.titleApply}>
                            <Checkbox
                                checked={tableType === TableType.ApplyToLesson}
                                onChange={(event) => {
                                    if (tableType === TableType.ApplyToLesson) {
                                        setTableType(undefined)
                                    } else {
                                        setTableType(TableType.ApplyToLesson)
                                    }
                                }}>
                                <span className={styles.applyToLessons}>
                                    APPLY TO LESSONS <span>(optional)</span>
                                </span>
                            </Checkbox>
                        </div>
                        <div className={styles.titleApply}>
                            <Checkbox
                                checked={tableType === TableType.ApplyToMissedAttendance}
                                onChange={(event) => {
                                    if (tableType === TableType.ApplyToMissedAttendance) {
                                        setTableType(undefined)
                                    } else {
                                        setTableType(TableType.ApplyToMissedAttendance)
                                    }
                                }}>
                                <span className={styles.applyToLessons}>
                                    APPLY TO MISSED ATTENDANCE <span>(optional)</span>
                                </span>
                            </Checkbox>
                        </div>
                    </div> */}
                    {tableType === TableType.ApplyToLesson ? (
                        <LessonTable selectedStudent={selectedStudent} setListLessons={setListLessons} />
                    ) : tableType === TableType.ApplyToMissedAttendance ? (
                        <MissedAttendanceTable selectedStudent={selectedStudent} setListLessons={setListLessons} />
                    ) : null}
                    {/* <div className={styles.separateLine} /> */}
                    <div className={styles.buttonSubmit}>
                        <SecondaryButton title={"CANCEL"} onClick={onCancel} />
                        {selectedStudent && model.isStaffOrAdmin() ? (
                            <BaseButton
                                title={"SAVE"}
                                onClick={onSave}
                                loading={editMakeupAttendanceMutate.isLoading}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                {selectedStudent && signaturePopup.isVisible && (
                    <SignaturePopup
                        isShow={signaturePopup.isVisible}
                        onClose={signaturePopup.close}
                        targetUser={{
                            type: selectedStudent?.type,
                            id: selectedStudent?.userId,
                            profileId: selectedStudent?.profileId
                        }}
                        useOneTimeSignature
                        title=""
                        autoClose
                        onSetNewSignature={onSaveSignature}
                        customTitle={renderTitleSignature}
                    />
                )}

                {advisorSignaturePopup.isVisible && (
                    <SignaturePopup
                        isShow={advisorSignaturePopup.isVisible}
                        onClose={advisorSignaturePopup.close}
                        targetUser={{
                            type: Auth.UserProfileType.Staff,
                            id: model.user.userId,
                            profileId: model.user.profileId
                        }}
                        title=""
                        actions={{
                            onCancel: advisorSignaturePopup.close,
                            onSign: onSaveAdvisorSignature
                        }}
                        autoClose
                        customTitle={renderTitleAdvisorSignature}
                        onSetNewSignature={onSaveAdvisorSignature}
                    />
                )}

                {instructorSignaturePopup.isVisible && (
                    <SignaturePopup
                        isShow={instructorSignaturePopup.isVisible}
                        onClose={instructorSignaturePopup.close}
                        targetUser={{
                            type: Auth.UserProfileType.Staff,
                            id: model.user.userId,
                            profileId: model.user.profileId
                        }}
                        title=""
                        actions={{
                            onCancel: instructorSignaturePopup.close,
                            onSign: onSaveInstructorSignature
                        }}
                        autoClose
                        customTitle={renderTitleInstructorSignature}
                        onSetNewSignature={onSaveInstructorSignature}
                    />
                )}
                <QRScanPopup
                    isVisible={popupQrCode.isVisible}
                    selectedAttendance={makeupAttendance}
                    onClose={popupQrCode.close}
                />
                <BaseLoading isShow={isFetchingMakeupData} />
            </BasePopup>
        </>
    )
}

export default EditMakeupAttendance
