import {Checkbox, Col, Radio} from "antd"
import styles from "./ClassroomAttendance.module.css"
import {useCallback, useEffect, useState} from "react"
import RoundingRange from "../DailyAttendance/RoundingRange"
import {useTranslation} from "react-i18next"
import {BaseButton, BaseLoading} from "components"
import {AttendanceRoundingItem, AttendanceRoundingType, GeneralSettingField} from "types/settings/general-setting"
import {handleError, toastError, toastSuccess} from "helpers"
import {cloneDeep, isEqual, pick} from "lodash"
import {useAttendanceRounding, useMutation} from "hooks"
import {RoundingType} from "../DailyAttendance"
import {generalSettingsService} from "services"
import {YesNo} from "types/common"

type ClassroomAttendanceProps = {}

const ClassroomAttendance = (props: ClassroomAttendanceProps) => {
    const [isRoundingEnabled, setIsRoundingEnabled] = useState(false)
    const [enableClassroomSpecialRoundingForCheckin, setEnableClassroomSpecialRoundingForCheckin] = useState(false)
    const {t} = useTranslation(["common"])
    const [attendanceRoundingRanges, setAttendanceRoundingRanges] = useState([])
    const [specialAttendanceRoundingRanges, setSpecialAttendanceRoundingRanges] = useState([])

    const attendanceRounding = useAttendanceRounding({
        type: AttendanceRoundingType.Classroom
    })

    const updateGeneralSettings = useMutation(
        async (payload: Array<{field: GeneralSettingField; checked: boolean}>) => {
            await generalSettingsService.updateSettings(
                payload.map(({field, checked}) => {
                    return {
                        field,
                        value: checked ? YesNo.Yes : YesNo.No
                    }
                })
            )
        },
        {
            onSuccess() {
                attendanceRounding.refetch()
            },
            onError: (err) => {
                handleError(err)
            }
        }
    )
    useEffect(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
        setEnableClassroomSpecialRoundingForCheckin(attendanceRounding.specialAttendanceRoundingEnabled)
    }, [attendanceRounding.isEnabled, attendanceRounding.specialAttendanceRoundingEnabled])

    useEffect(() => {
        setAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.attendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
        setSpecialAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.specialAttendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
    }, [attendanceRounding.attendanceRoundingRanges, attendanceRounding.specialAttendanceRoundingRanges])

    const validateAttendanceRoundingItem = (attendanceRoundingRanges: AttendanceRoundingItem[]) => {
        for (let attendanceRounding of attendanceRoundingRanges) {
            if (
                !attendanceRounding.from?.toString()?.length ||
                !attendanceRounding.to?.toString()?.length ||
                !attendanceRounding.value?.toString()?.length
            ) {
                return "From, To, Value cannot be empty."
            }
            if (Number(attendanceRounding.from) > Number(attendanceRounding.to)) {
                return "From must be greater than or equal To"
            }
        }
        return false
    }

    const onClickSave = useCallback(async () => {
        let errorAttendanceRoundingRanges = validateAttendanceRoundingItem(attendanceRoundingRanges)
        let errorSpecialAttendanceRoundingRanges = validateAttendanceRoundingItem(specialAttendanceRoundingRanges)
        if (errorAttendanceRoundingRanges || errorSpecialAttendanceRoundingRanges) {
            toastError((errorAttendanceRoundingRanges || errorSpecialAttendanceRoundingRanges) as string)
            return
        }
        try {
            updateGeneralSettings.mutate([
                {
                    field: GeneralSettingField.ClassroomSpecialRoundingForCheckin,
                    checked: enableClassroomSpecialRoundingForCheckin
                }
            ])
            await attendanceRounding.update({
                attendanceRoundingRanges: attendanceRoundingRanges.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                specialAttendanceRoundingRanges: specialAttendanceRoundingRanges.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                enabled: isRoundingEnabled,
                type: AttendanceRoundingType.Classroom
            })
            toastSuccess("Updated")
        } catch (error: any) {
            handleError(error)
        }
    }, [
        attendanceRounding,
        attendanceRoundingRanges,
        enableClassroomSpecialRoundingForCheckin,
        isRoundingEnabled,
        specialAttendanceRoundingRanges,
        updateGeneralSettings
    ])

    const onClickCancelBtn = useCallback(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
        setEnableClassroomSpecialRoundingForCheckin(attendanceRounding.specialAttendanceRoundingEnabled)
        setAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.attendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
        setSpecialAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.specialAttendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
    }, [
        attendanceRounding.attendanceRoundingRanges,
        attendanceRounding.isEnabled,
        attendanceRounding.specialAttendanceRoundingEnabled,
        attendanceRounding.specialAttendanceRoundingRanges
    ])

    const isChangedTableData = () => {
        const fieldNames = ["from", "to", "value"]
        if (
            attendanceRounding.attendanceRoundingRanges.length !== attendanceRoundingRanges.length ||
            attendanceRounding.specialAttendanceRoundingRanges.length !== specialAttendanceRoundingRanges.length
        ) {
            return true
        }

        for (let i = 0; i < attendanceRounding.attendanceRoundingRanges.length; i++) {
            const oldData = pick(attendanceRounding.attendanceRoundingRanges[i], fieldNames)
            const newData = pick(attendanceRoundingRanges[i], fieldNames)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        for (let i = 0; i < attendanceRounding.specialAttendanceRoundingRanges.length; i++) {
            const oldData = pick(attendanceRounding.specialAttendanceRoundingRanges[i], fieldNames)
            const newData = pick(specialAttendanceRoundingRanges[i], fieldNames)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        return false
    }
    const isChanged =
        isRoundingEnabled !== attendanceRounding.isEnabled ||
        attendanceRounding.specialAttendanceRoundingEnabled !== enableClassroomSpecialRoundingForCheckin ||
        isChangedTableData()

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Attendance checkin rounding</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Radio.Group
                    name=""
                    className={styles.radioGroup}
                    onChange={(event) => {
                        setIsRoundingEnabled(event.target.value === RoundingType.Rounding)
                    }}
                    value={isRoundingEnabled ? RoundingType.Rounding : RoundingType.NoRounding}>
                    <Radio className={styles.radioItem} value={RoundingType.NoRounding}>
                        No Rounding
                    </Radio>
                    <Radio className={styles.radioItem} value={RoundingType.Rounding}>
                        Rounding (Rounding to the minutes not seconds)
                    </Radio>
                </Radio.Group>
                {isRoundingEnabled ? (
                    <>
                        <Col span={24} className={styles.checkboxContainer}>
                            <Checkbox
                                checked={enableClassroomSpecialRoundingForCheckin}
                                onChange={(event) => setEnableClassroomSpecialRoundingForCheckin(event.target.checked)}>
                                Special rounding condition for checkin (base on shift start time)
                            </Checkbox>
                        </Col>
                        {enableClassroomSpecialRoundingForCheckin ? (
                            <RoundingRange
                                isSpecialCondition
                                listData={specialAttendanceRoundingRanges}
                                setListData={setSpecialAttendanceRoundingRanges}
                            />
                        ) : null}
                        <hr className={styles.line} />
                        <span>Rounding logic (Applies to all events - checkin, checkout)</span>
                        <RoundingRange listData={attendanceRoundingRanges} setListData={setAttendanceRoundingRanges} />
                    </>
                ) : null}
            </Col>
            <div className={styles.action}>
                <BaseButton
                    title={t("common:action.cancel")}
                    variant="secondary"
                    className={styles.cancelBtn}
                    disabled={!isChanged}
                    onClick={onClickCancelBtn}
                />
                <BaseButton
                    title={t("common:action.save")}
                    onClick={onClickSave}
                    disabled={!isChanged}
                    loading={attendanceRounding.isUpdating}
                />
            </div>
            <BaseLoading isShow={attendanceRounding.isUpdating || attendanceRounding.isFetching} />
        </div>
    )
}

export {ClassroomAttendance}
