/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useMemo, useState} from "react"
import {Checkbox, Col, Row, Tabs} from "antd"
import {useActiveTab, useModel, useVisible} from "hooks"
import {TabHeader} from "components/Tab"
import {DailyAttendance} from "./DailyAttendance"
import dailyAttendanceStyles from "./DailyAttendance.module.css"
import {BaseButton, BaseLoading} from "components"
import {DailyChart} from "./DailyChart"
import {attendanceService} from "services"
import {handleError} from "helpers"
import {useQuery} from "@tanstack/react-query"
import moment from "moment"
import {DailyAttendanceViewType} from "types/attendance"

const {TabPane} = Tabs

const DAILY_ATTENDANCE_TABS = {
    ALL: "all",
    DAILY: "daily"
}

type Props = {
    studentProfileId?: number
}

export function DailyAttendanceTab(props: Props) {
    const model = useModel()

    const allStatsInfoVisible = useVisible(false)

    const {studentProfileId} = props
    const [viewType, setViewType] = useState<DailyAttendanceViewType>(DailyAttendanceViewType.ViewByProgram)
    const [activeTab, onChangeTab] = useActiveTab(DAILY_ATTENDANCE_TABS.ALL, "sub-type")
    const [filterCharts, setFilterCharts] = useState<{
        majorVersionIds: number[]
        phaseIds: number[]
        eventType?: string
    }>({
        majorVersionIds: [],
        phaseIds: [],
        eventType: undefined
    })

    const {isFetching, data: statsInfo} = useQuery(
        ["daily-attendance-stats", studentProfileId, viewType],
        () =>
            attendanceService.getDailyAttendanceStatsNew({
                viewType,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            }),
        {
            initialData: [],
            onError: (err) => {
                handleError(err)
            }
        }
    )

    const resetFilterCharts = useCallback(() => {
        setFilterCharts({
            majorVersionIds: [],
            phaseIds: [],
            eventType: undefined
        })
    }, [])

    const onClickDailyChartItem = (item, type) => {
        if (
            viewType === DailyAttendanceViewType.ViewByPhase &&
            filterCharts.phaseIds.includes(item.phaseId) &&
            filterCharts.eventType === type
        ) {
            resetFilterCharts()
        } else if (
            viewType === DailyAttendanceViewType.ViewByProgram &&
            filterCharts.majorVersionIds.includes(item.id) &&
            filterCharts.eventType === type
        ) {
            resetFilterCharts()
        } else {
            setFilterCharts({
                majorVersionIds: item.id ? [item.id] : [],
                eventType: type,
                phaseIds: item.phaseId ? [item.phaseId] : []
            })
        }
    }

    const toggleViewType = useCallback(() => {
        setViewType(
            viewType === DailyAttendanceViewType.ViewByProgram
                ? DailyAttendanceViewType.ViewByPhase
                : DailyAttendanceViewType.ViewByProgram
        )
        resetFilterCharts()
    }, [viewType])

    const renderStats = useMemo(() => {
        const statsInfoData = !allStatsInfoVisible.isVisible
            ? (statsInfo as any).filter((stat) => stat.activeStudentsCount > 0)
            : statsInfo
        return (
            <Row gutter={[40, 40]}>
                {statsInfoData.map((item) => (
                    <Col key={item.id || item.phaseId} span={8}>
                        <DailyChart
                            viewType={viewType}
                            data={item}
                            filter={filterCharts}
                            onClickItem={onClickDailyChartItem}
                        />
                    </Col>
                ))}
            </Row>
        )
    }, [allStatsInfoVisible, statsInfo, filterCharts, viewType])

    return (
        <div>
            {model.isStaffOrAdmin() && (
                <div className={dailyAttendanceStyles.checkbox}>
                    <Checkbox checked={allStatsInfoVisible.isVisible} onChange={allStatsInfoVisible.toggle}>
                        Show All
                    </Checkbox>
                    <BaseButton
                        variant="secondary"
                        title={viewType === DailyAttendanceViewType.ViewByPhase ? "View By Program" : "View By Phase"}
                        onClick={toggleViewType}
                    />
                </div>
            )}
            {renderStats}
            <div className={dailyAttendanceStyles.tabContainer}>
                <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={onChangeTab}>
                    <TabPane key={DAILY_ATTENDANCE_TABS.ALL} tab={<TabHeader title="All" />}>
                        {activeTab === DAILY_ATTENDANCE_TABS.ALL && (
                            <DailyAttendance
                                studentProfileId={studentProfileId}
                                statsInfo={statsInfo}
                                filter={filterCharts}
                                showAll
                            />
                        )}
                    </TabPane>
                    <TabPane key={DAILY_ATTENDANCE_TABS.DAILY} tab={<TabHeader title="Daily" />}>
                        {activeTab === DAILY_ATTENDANCE_TABS.DAILY && (
                            <DailyAttendance
                                studentProfileId={studentProfileId}
                                statsInfo={statsInfo}
                                filter={filterCharts}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </div>
            <BaseLoading isShow={isFetching} />
        </div>
    )
}
