/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {v4 as uuid} from "uuid"
import {useTranslation, withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {handleError, toastError, toastWarning} from "helpers"
import {settingLeadTypesService} from "services"
import {KlassappTableHOC} from "HOC"
import styles from "./LeadTypes.module.css"
import {ActivityStatus} from "components/ui"
import {Checkbox} from "antd"
import {FilterKey} from "types/filter"
import {useModel} from "hooks"
import {isEmpty} from "lodash"

function LeadTypes(props: KlassappTableProps) {
    const model = useModel()
    const [newItemData, setNewItemData] = useState<any>(null)
    const [oldItemData, setOldItemData] = useState<any>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const storageData = model.getStorageFilter(FilterKey.LeadTypes)
    const [search, setSearch] = useState<string>(!isEmpty(storageData) ? storageData.search : "")
    const {t} = useTranslation(["admission", "user", "common"])

    useEffect(() => {
        props.dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getAllFields", func: getAllFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [search])

    useEffect(() => {
        const newData = props.data.map((item) => {
            const isInputItem = item.id === newItemData?.id
            return {
                ...item,
                codeHtml: isInputItem ? renderTextInput("codeData") : item.code,
                nameHtml: isInputItem ? renderTextInput("nameData") : item.name,
                statusHtml: isInputItem
                    ? renderIsActiveCheckbox()
                    : renderStatusHtml(item.isActive ? "active" : "inactive")
            }
        })
        props.dispatch({data: newData})
    }, [newItemData])

    const getPageTitle = () => {
        return t("admission.leadTypes.title")
    }

    const getFields = () => {
        return [t("admission.leadTypes.code"), t("admission.leadTypes.name"), t("admission.leadTypes.status")]
    }

    const getAllFields = () => {
        return [t("admission.leadTypes.code"), t("admission.leadTypes.name"), t("admission.leadTypes.status")]
    }

    const getData = async () => {
        props.dispatch({isLoading: true})
        const params = getParams()

        try {
            const {data, total} = await settingLeadTypesService.getAll(params)
            const newData = data.map((item) => {
                item.id = item.leadTypeId
                item.codeHtml = item.code
                item.nameHtml = item.name
                item.statusHtml = renderStatusHtml(item.isActive ? "active" : "inactive")
                return item
            })
            props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const renderStatusHtml = (status) => {
        return <ActivityStatus status={status} />
    }

    const getDataSubmit = () => {
        const {codeData, nameData, isActiveData} = newItemData
        const submitData = {
            code: codeData,
            name: nameData,
            isActive: !!isActiveData
        }
        return submitData
    }

    const getParams = () => {
        const {page, pageSize} = props
        const params = {
            filter: {search},
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    const getColumns = () => {
        return [
            {
                title: t("admission.leadTypes.code"),
                field: "codeHtml"
            },
            {
                title: t("admission.leadTypes.name"),
                field: "nameHtml"
            },
            {
                title: t("admission.leadTypes.status"),
                field: "statusHtml",
                style: {maxWidth: "200px"}
            }
        ]
    }

    const onClickDeleteMulti = async () => {
        const checkedItems = props.getCurrentData().filter((item) => item.isChecked)

        const payload = checkedItems.map((item) => item.id)
        try {
            props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingLeadTypesService.delete({leadTypeIds: payload})
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editItem) => {
        if (newItemData) {
            toastWarning(t("common:validation.saveBeforeEdit"))
            return
        }
        setNewItemData({
            ...editItem,
            codeData: editItem.code,
            nameData: editItem.name,
            isActiveData: editItem.isActive,
            isEdit: true
        })
        setOldItemData({...editItem})
    }

    const onClickDelete = async (deletedItem) => {
        if (deletedItem.isForm) {
            setNewItemData(null)
            setOldItemData(null)
            await getData()
            return
        }
        try {
            props.dispatch({isLoading: true})
            setNewItemData(null)
            setOldItemData(null)
            await settingLeadTypesService.delete({
                leadTypeIds: [deletedItem.leadTypeId]
            })
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const renderTextInput = (valueKey: "codeData" | "nameData") => {
        return (
            <BaseInput
                value={newItemData[valueKey]}
                onChange={(value) => {
                    setNewItemData({...newItemData, [valueKey]: value})
                }}
            />
        )
    }

    const onClickAddItem = () => {
        const newItem = {
            id: uuid(),
            codeData: "",
            nameData: "",
            isActiveData: false,
            isForm: true
        }
        const {data} = props
        data.push(newItem)
        setNewItemData(newItem)
        setOldItemData(null)
        props.dispatch({data})
    }

    const validateBeforeSubmit = () => {
        const attrs = {
            codeData: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Code"})},
            nameData: {
                type: "string",
                errorMessage: t("common:validation.cantEmpty", {field: "Name"})
            }
        }
        for (let [key, value] of Object.entries(attrs)) {
            if (!newItemData[key]) {
                toastError(value.errorMessage)
                return false
            } else if (
                (value.type === "string" && newItemData[key].trim() === "") ||
                (value.type === "array" && newItemData[key].length < 1) ||
                (value.type === "object" && Object.keys(newItemData[key]).length < 1)
            ) {
                toastError(value.errorMessage)
                return false
            }
        }
        return true
    }

    const onClickSave = async () => {
        const {isForm, id} = newItemData
        if (validateBeforeSubmit()) {
            const submitData = getDataSubmit()
            if (isForm) {
                await create({...submitData})
            } else {
                await update(id, submitData)
            }
            setNewItemData(null)
            setOldItemData(null)
            getData()
            props.dispatch({isShowTableHeaderAction: false})
        }
    }

    const create = async (data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingLeadTypesService.create(data)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const update = async (id, data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingLeadTypesService.update(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const onClickCancel = () => {
        const newData = props.data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.codeHtml = oldItemData.code
                    item.nameHtml = oldItemData.name
                    item.statusHtml = renderStatusHtml(oldItemData.isActive ? "active" : "inactive")
                    return item
                }
                return item
            })
        setNewItemData(null)
        setOldItemData(null)
        props.dispatch({data: newData})
    }

    const getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const {onClickShowConfirmModal} = props
        const actions = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
        return actions
    }

    const onSearchInput = (value) => {
        model.updateStorageFilter(FilterKey.LeadTypes, {search: value})
        setSearch(value)
    }

    const renderIsActiveCheckbox = () => {
        return (
            <div className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isActiveData}
                    onChange={(event) => {
                        setNewItemData({...newItemData, isActiveData: event.target.checked})
                    }}
                />
            </div>
        )
    }

    return (
        <div className={styles.wrapper}>
            <BaseNewFilter searchValue={search} onSearchInput={onSearchInput} />
            <KlassappTableHeader
                isShowAction={props.isShowTableHeaderAction}
                actions={props.tableHeaderActions}
                page={props.page}
                total={props.total}
                defaultPageSize={props.pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={props.fields}
                allFields={props.allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={props.columns}
                data={props.data}
                menuActions={props.isHideMenuActions ? [] : props.menuActions}
                isLoading={props.isLoading}
                fields={props.fields}
                allFields={props.allFields}
                isShowCheckedColumn
                onClickRowItem={() => {}}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                className={styles.tableScrollHidden}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            {!newItemData ? (
                <div className={styles.buttonWrap}>
                    <AddItemCircleButton onClick={onClickAddItem} />
                </div>
            ) : (
                <div className={styles.buttonWrap}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        onClick={onClickCancel}
                        className={styles.cancelBtn}
                    />
                    <BaseButton
                        title={t("common:action.save").toUpperCase()}
                        onClick={onClickSave}
                        loading={isSubmitting}
                    />
                </div>
            )}
        </div>
    )
}

export default KlassappTableHOC(LeadTypes)
