/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react"
import {Tabs} from "antd"
import {isEqual} from "lodash"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {BaseButton, SecondaryButton} from "components/buttons"
import {departmentsService, generalSettingsService} from "services"
import {getFullName, handleError, toastError} from "helpers"
import {BaseDepartmentId} from "types/departments"
import {Activities, Statuses} from "sections/Settings"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {AcademicTrack} from "./../AcademicTrack"
import styles from "./GeneralSettings.module.css"
import IntegrationMapping from "sections/Settings/IntegrationMapping"
import {TabHeader} from "components/Tab"
import {FormLabel} from "components/Form"
import {useAllDepartments, useModel} from "hooks"
import {GeneralSettingField} from "types/settings/general-setting"
import {useMutation, useQuery} from "@tanstack/react-query"
import {YesNo} from "types/common"
import {Phase} from "../Phases/Phases"
import {Shift} from "../Shifts"
import ClockHoursTranscript from "sections/Settings/ClockHoursTranscript"
import {DailyAttendance} from "../DailyAttendance"
import {ClassroomAttendance} from "../ClassroomAttendance"

const {TabPane} = Tabs

function reducer(state, action) {
    return {...state, ...action}
}

export enum SettingsTab {
    Statuses = "statuses",
    Activities = "activities",
    LeadSources = "lead-sources",
    AcademicTrack = "academic-track",
    DailyAttendance = "daily-attendance",
    ClassroomAttendance = "classroom-attendance",
    IntegrationMapping = "integration-mapping",
    ClockHoursTranscript = "clock-hours-transcript",
    Phases = "phases",
    Shifts = "shifts"
}

export function GeneralSettings(props) {
    const {departmentId} = props
    const model = useModel()
    const {invalidateDepartmentsCache} = useAllDepartments()
    const {departments: allDepartments} = useAllDepartments()
    const initialState = {
        activeTabKey:
            departmentId !== BaseDepartmentId.CareerServices && !model.clientSetting.isDepartmentalStatusesHidden
                ? SettingsTab.Statuses
                : SettingsTab.Activities,
        directors: [],
        activeDirectors: [],
        oldActiveDirectors: "",
        isSubmitting: false,
        selectedProgressReport: [],
        oldSelectedProgressReport: []
    }
    const updateGeneralSettings = useMutation(
        async ({field, checked}: {field: GeneralSettingField; checked: boolean}) => {
            await generalSettingsService.updateSettings([
                {
                    field,
                    value: checked ? YesNo.Yes : YesNo.No
                }
            ])
        },
        {
            onError: (error: Error) => {
                toastError(error.message)
            }
        }
    )
    const generalSettingsQuery = useQuery(
        ["sap-academic-setting"],
        async () => {
            return await generalSettingsService.getSettings([
                GeneralSettingField.ProgressReportSystemDefault,
                GeneralSettingField.ProgressReportSubmodulesBreakDown
            ])
        },
        {
            initialData: []
        }
    )
    const [
        {
            activeTabKey,
            activeDirectors,
            oldActiveDirectors,
            isSubmitting,
            selectedProgressReport,
            oldSelectedProgressReport
        },
        dispatch
    ] = useReducer(reducer, initialState)
    const {t} = useTranslation(["academics", "common"])
    const {isModuleEnable} = useEdularModulesContext()
    const progressReportOptions = [
        {id: GeneralSettingField.ProgressReportSystemDefault, name: "System default"},
        {id: GeneralSettingField.ProgressReportSubmodulesBreakDown, name: "Submodules breakdown (Clock hour only)"}
    ]

    useEffect(() => {
        if (!generalSettingsQuery.isFetched) return
        const selectedProgressReport = generalSettingsQuery.data
            ?.filter((item) => item.value === YesNo.Yes)
            .map((item) => ({
                id: item.field,
                name:
                    item.field === GeneralSettingField.ProgressReportSystemDefault
                        ? "System default"
                        : "Submodules breakdown (Clock hour only)"
            }))
        dispatch({
            oldSelectedProgressReport: selectedProgressReport,
            selectedProgressReport
        })
    }, [generalSettingsQuery.isFetched, generalSettingsQuery.data])

    useEffect(() => {
        getActiveDirectors()
    }, [])

    async function getActiveDirectors() {
        try {
            const departments = allDepartments.filter((dept) => departmentId === dept.departmentId)
            if (departments && departments.length) {
                const activeDirectors = departments[0].directors
                dispatch({
                    activeDirectors,
                    oldActiveDirectors: activeDirectors
                })
            }
        } catch (error) {
            handleError(error)
        }
    }

    function onChangeTab(activeKey) {
        dispatch({activeTabKey: activeKey})
    }

    function onChangeAdmissionsDirector(value) {
        dispatch({activeDirectors: value})
    }

    function onClickCancel() {
        dispatch({activeDirectors: oldActiveDirectors, selectedProgressReport: oldSelectedProgressReport})
    }

    async function onClickSave() {
        try {
            dispatch({isSubmitting: true})
            const profileIds = activeDirectors.map((item) => item.profileId)
            const progressReportSystemDefaultValue = !!selectedProgressReport?.find(
                (item) => item.id === GeneralSettingField.ProgressReportSystemDefault
            )
            const progressReportSubmoduleBreakDownValue = !!selectedProgressReport?.find(
                (item) => item.id === GeneralSettingField.ProgressReportSubmodulesBreakDown
            )
            await Promise.all([
                departmentsService.edit({
                    departmentId,
                    directorProfileIds: profileIds
                }),
                updateGeneralSettings.mutate({
                    field: GeneralSettingField.ProgressReportSystemDefault,
                    checked: progressReportSystemDefaultValue
                }),
                updateGeneralSettings.mutate({
                    field: GeneralSettingField.ProgressReportSubmodulesBreakDown,
                    checked: progressReportSubmoduleBreakDownValue
                })
            ])

            await Promise.all([getActiveDirectors(), generalSettingsQuery.refetch()])
            invalidateDepartmentsCache()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    const loadOptions = async (input, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                range: {
                    page,
                    pageSize
                }
            }
            const {data: directors, total} = await departmentsService.searchDirectors(input, params)
            return {
                options: directors,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getDirectorTitle = () => {
        switch (departmentId) {
            case BaseDepartmentId.Academics:
                return "Academics Director"
            case BaseDepartmentId.Admissions:
                return t("settings.director")
            case BaseDepartmentId.Alumni:
                return "Alumni Director"
            case BaseDepartmentId.CareerServices:
                return "Career Service Director"
            case BaseDepartmentId.FinancialAid:
                return "Financial Aid Director"
            case BaseDepartmentId.StudentAccount:
                return "Student Account Director"
            case BaseDepartmentId.StudentServices:
                return "Student Service Director"
            default:
                break
        }
    }

    const debounceUsers = debounce(loadOptions, 500)
    const isChanged =
        !isEqual(activeDirectors, oldActiveDirectors) || !isEqual(selectedProgressReport, oldSelectedProgressReport)

    return (
        <div className={styles.wrapper}>
            <div className={styles.settingWrap}>
                <div className={styles.fieldWrap}>
                    <FormLabel label={getDirectorTitle()} />
                    <KlassDropAsyncPaginate
                        value={activeDirectors}
                        valueKey="profileId"
                        onChange={onChangeAdmissionsDirector}
                        getOptionLabel={(option: any) => getFullName(option)}
                        loadOptions={debounceUsers}
                        isMulti
                        placeholder="Director"
                    />
                </div>
                <div className={styles.fieldWrap}>
                    <FormLabel label="Progress Report / Transcript" />
                    <KlassDropdown
                        options={progressReportOptions}
                        value={selectedProgressReport}
                        isMulti
                        onChange={(newValue) => dispatch({selectedProgressReport: newValue})}
                    />
                </div>
                <PermissionsRequired permissions={{staff: [Permissions.Staff.Settings.Modules.Academics.Edit]}}>
                    {isChanged && (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                onClick={onClickCancel}
                                className={styles.cancelBtn}
                            />
                            <BaseButton
                                title={t("common:action.save").toUpperCase()}
                                onClick={onClickSave}
                                loading={isSubmitting}
                            />
                        </div>
                    )}
                </PermissionsRequired>
            </div>
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                {departmentId !== BaseDepartmentId.CareerServices && !model.clientSetting.isDepartmentalStatusesHidden && (
                    <TabPane tab={<TabHeader title={t("settings.statuses")} />} key={SettingsTab.Statuses}>
                        <Statuses departmentId={departmentId} model={props.model} includeStage={false} />
                    </TabPane>
                )}
                {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                    <TabPane tab={<TabHeader title={t("settings.activities")} />} key={SettingsTab.Activities}>
                        <Activities departmentId={departmentId} model={props.model} />
                    </TabPane>
                )}
                {departmentId === BaseDepartmentId.Academics && (
                    <>
                        <TabPane
                            tab={<TabHeader title={t("settings.academicTrack")} />}
                            key={SettingsTab.AcademicTrack}>
                            <AcademicTrack model={props.model} />
                        </TabPane>
                        <TabPane
                            tab={<TabHeader title={t("settings.dailyAttendance")} />}
                            key={SettingsTab.DailyAttendance}>
                            {activeTabKey === SettingsTab.DailyAttendance ? <DailyAttendance /> : null}
                        </TabPane>
                        <TabPane
                            tab={<TabHeader title={t("settings.classroomAttendance")} />}
                            key={SettingsTab.ClassroomAttendance}>
                            {activeTabKey === SettingsTab.ClassroomAttendance ? <ClassroomAttendance /> : null}
                        </TabPane>
                        <TabPane
                            tab={<TabHeader title={t("settings.integrationMapping")} />}
                            key={SettingsTab.IntegrationMapping}>
                            <IntegrationMapping model={props.model} />
                        </TabPane>
                    </>
                )}
                <TabPane tab={<TabHeader title={t("settings.phases")} />} key={SettingsTab.Phases}>
                    <Phase model={props.model} />
                </TabPane>
                <TabPane tab={<TabHeader title={t("settings.shifts")} />} key={SettingsTab.Shifts}>
                    <Shift model={props.model} />
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("settings.clockHoursTranscript")} />}
                    key={SettingsTab.ClockHoursTranscript}>
                    <ClockHoursTranscript />
                </TabPane>
            </Tabs>
        </div>
    )
}
