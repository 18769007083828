import {useMutation, useQuery} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {generalSettingsService} from "services"
import {AttendanceRoundingType, EditAttendanceRoundingPayload} from "types/settings/general-setting"

type AttendanceRoundingParams = {
    type: AttendanceRoundingType
    hasToBeFetched?: boolean
}

const useAttendanceRounding = ({type, hasToBeFetched = true}: AttendanceRoundingParams) => {
    const {
        data: {
            attendanceRoundingRanges,
            specialAttendanceRoundingRanges,
            enabled: enableAttendanceRounding,
            specialAttendanceRoundingEnabled
        },
        isFetching,
        refetch
    } = useQuery(
        [`${type}-attendance-rounding-settings`, type],
        async () =>
            generalSettingsService.listAttendanceRounding({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                filter: {
                    type: type
                }
            }),
        {
            enabled: hasToBeFetched,
            initialData: {
                attendanceRoundingRanges: [],
                specialAttendanceRoundingRanges: [],
                total: 0,
                enabled: false,
                specialAttendanceRoundingEnabled: false
            }
        }
    )

    const update = useMutation(
        async (payload: EditAttendanceRoundingPayload) => {
            await generalSettingsService.editAttendanceRounding(payload)
        },
        {
            onSuccess: () => {
                refetch()
            }
        }
    )

    return {
        update: update.mutateAsync,
        isUpdating: update.isLoading,
        isEnabled: enableAttendanceRounding,
        specialAttendanceRoundingEnabled,
        attendanceRoundingRanges,
        specialAttendanceRoundingRanges,
        isFetching,
        refetch
    }
}

export {useAttendanceRounding}
