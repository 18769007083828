import {Checkbox, Col, Radio} from "antd"
import styles from "./DailyAttendance.module.css"
import {useCallback, useEffect, useState} from "react"
import RoundingRange from "./RoundingRange"
import {BaseButton, BaseLoading} from "components"
import {useTranslation} from "react-i18next"
import {AttendanceRoundingItem, AttendanceRoundingType, GeneralSettingField} from "types/settings/general-setting"
import {handleError, toastError, toastSuccess} from "helpers"
import {cloneDeep, isEqual, pick} from "lodash"
import {useAttendanceRounding, useMakeupAttendanceSettings} from "hooks"
import {useMutation} from "@tanstack/react-query"
import {generalSettingsService} from "services"
import {YesNo} from "types/common"

type DailyAttendanceProps = {}
export enum RoundingType {
    NoRounding = "NoRounding",
    Rounding = "Rounding"
}
const DailyAttendance = (props: DailyAttendanceProps) => {
    const [isRoundingEnabled, setIsRoundingEnabled] = useState(false)
    const {t} = useTranslation(["common"])
    const settings = useMakeupAttendanceSettings()
    const [enableDocumentAttachment, setEnableDocumentAttachment] = useState(false)
    const [enableDocumentAttachmentMandatory, setEnableDocumentAttachmentMandatory] = useState(false)
    const [enableRemoteMakeupAttendance, setEnableRemoteMakeupAttendance] = useState(false)
    const [enableDailySpecialRoundingForCheckin, setEnableDailySpecialRoundingForCheckin] = useState(false)
    const [attendanceRoundingRanges, setAttendanceRoundingRanges] = useState([])
    const [specialAttendanceRoundingRanges, setSpecialAttendanceRoundingRanges] = useState([])

    const attendanceRounding = useAttendanceRounding({
        type: AttendanceRoundingType.Daily
    })

    const updateGeneralSettings = useMutation(
        async (payload: Array<{field: GeneralSettingField; checked: boolean}>) => {
            await generalSettingsService.updateSettings(
                payload.map(({field, checked}) => {
                    return {
                        field,
                        value: checked ? YesNo.Yes : YesNo.No
                    }
                })
            )
        },
        {
            onSuccess() {
                settings.refetch()
            },
            onError: (err) => {
                handleError(err)
            }
        }
    )

    useEffect(() => {
        setEnableDocumentAttachment(settings.isMakeupDocumentAttachmentEnabled)
        setEnableRemoteMakeupAttendance(settings.isRemoteMakeupDocumentEnabled)
        setEnableDocumentAttachmentMandatory(settings.isMakeupDocumentAttachmentMandatory)
        setEnableDailySpecialRoundingForCheckin(attendanceRounding.specialAttendanceRoundingEnabled)
    }, [
        settings.isMakeupDocumentAttachmentEnabled,
        settings.isRemoteMakeupDocumentEnabled,
        settings.isMakeupDocumentAttachmentMandatory,
        attendanceRounding.specialAttendanceRoundingEnabled
    ])

    useEffect(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
    }, [attendanceRounding.isEnabled])

    useEffect(() => {
        setAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.attendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
        setSpecialAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.specialAttendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
    }, [attendanceRounding.attendanceRoundingRanges, attendanceRounding.specialAttendanceRoundingRanges])

    const validateAttendanceRoundingItem = (attendanceRoundingRanges: AttendanceRoundingItem[]) => {
        for (let attendanceRounding of attendanceRoundingRanges) {
            if (
                !attendanceRounding.from?.toString()?.length ||
                !attendanceRounding.to?.toString()?.length ||
                !attendanceRounding.value?.toString()?.length
            ) {
                return "From, To, Value cannot be empty."
            }
            if (Number(attendanceRounding.from) > Number(attendanceRounding.to)) {
                return "From must be greater than or equal To"
            }
        }
        return false
    }

    const onClickSave = useCallback(async () => {
        let errorAttendanceRoundingRanges = validateAttendanceRoundingItem(attendanceRoundingRanges)
        let errorSpecialAttendanceRoundingRanges = validateAttendanceRoundingItem(specialAttendanceRoundingRanges)
        if (errorAttendanceRoundingRanges || errorSpecialAttendanceRoundingRanges) {
            toastError((errorAttendanceRoundingRanges || errorSpecialAttendanceRoundingRanges) as string)
            return
        }
        try {
            updateGeneralSettings.mutate([
                {
                    field: GeneralSettingField.MakeupDocumentAttachment,
                    checked: enableDocumentAttachment
                },
                {
                    field: GeneralSettingField.MakeupRemote,
                    checked: enableRemoteMakeupAttendance
                },
                {
                    field: GeneralSettingField.MakeupDocumentAttachmentMandatory,
                    checked: enableDocumentAttachmentMandatory
                },
                {
                    field: GeneralSettingField.DailySpecialRoundingForCheckin,
                    checked: enableDailySpecialRoundingForCheckin
                }
            ])

            await attendanceRounding.update({
                attendanceRoundingRanges: attendanceRoundingRanges.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                specialAttendanceRoundingRanges: specialAttendanceRoundingRanges.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                enabled: isRoundingEnabled,
                type: AttendanceRoundingType.Daily
            })

            toastSuccess("Updated")
        } catch (error: any) {
            handleError(error)
        }
    }, [
        attendanceRoundingRanges,
        specialAttendanceRoundingRanges,
        updateGeneralSettings,
        enableDocumentAttachment,
        enableRemoteMakeupAttendance,
        enableDocumentAttachmentMandatory,
        enableDailySpecialRoundingForCheckin,
        attendanceRounding,
        isRoundingEnabled
    ])

    const onClickCancelBtn = useCallback(() => {
        setEnableDocumentAttachment(settings.isMakeupDocumentAttachmentEnabled)
        setEnableDocumentAttachmentMandatory(settings.isMakeupDocumentAttachmentMandatory)
        setEnableRemoteMakeupAttendance(settings.isRemoteMakeupDocumentEnabled)
        setIsRoundingEnabled(attendanceRounding.isEnabled)
        setEnableDailySpecialRoundingForCheckin(attendanceRounding.specialAttendanceRoundingEnabled)
        setAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.attendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
        setSpecialAttendanceRoundingRanges(
            cloneDeep(attendanceRounding.specialAttendanceRoundingRanges.map((item, index) => ({...item, id: index})))
        )
    }, [attendanceRounding, settings])

    const isChangedTableData = () => {
        const fieldNames = ["from", "to", "value"]

        if (
            attendanceRounding.attendanceRoundingRanges.length !== attendanceRoundingRanges.length ||
            attendanceRounding.specialAttendanceRoundingRanges.length !== specialAttendanceRoundingRanges.length
        ) {
            return true
        }

        for (let i = 0; i < attendanceRounding.attendanceRoundingRanges.length; i++) {
            const oldData = pick(attendanceRounding.attendanceRoundingRanges[i], fieldNames)
            const newData = pick(attendanceRoundingRanges[i], fieldNames)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        for (let i = 0; i < attendanceRounding.specialAttendanceRoundingRanges.length; i++) {
            const oldData = pick(attendanceRounding.specialAttendanceRoundingRanges[i], fieldNames)
            const newData = pick(specialAttendanceRoundingRanges[i], fieldNames)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        return false
    }
    const isChanged =
        settings.isMakeupDocumentAttachmentEnabled !== enableDocumentAttachment ||
        settings.isMakeupDocumentAttachmentMandatory !== enableDocumentAttachmentMandatory ||
        settings.isRemoteMakeupDocumentEnabled !== enableRemoteMakeupAttendance ||
        attendanceRounding.specialAttendanceRoundingEnabled !== enableDailySpecialRoundingForCheckin ||
        isRoundingEnabled !== attendanceRounding.isEnabled ||
        isChangedTableData()

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Document attachment</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Checkbox
                    checked={enableDocumentAttachment}
                    onChange={(event) => setEnableDocumentAttachment(event.target.checked)}>
                    Enable
                </Checkbox>
            </Col>
            {enableDocumentAttachment ? (
                <Col span={24} style={{paddingLeft: 20, marginTop: 20}}>
                    <Radio.Group
                        name=""
                        className={styles.radioGroup}
                        onChange={(event) => {
                            setEnableDocumentAttachmentMandatory(event.target.value)
                        }}
                        value={enableDocumentAttachmentMandatory}>
                        <Radio className={styles.radioItem} value={false}>
                            Non Mandatory
                        </Radio>
                        <Radio className={styles.radioItem} value={true}>
                            Mandatory
                        </Radio>
                    </Radio.Group>
                </Col>
            ) : null}
            <hr className={styles.line} />
            <span className={styles.title}>Remote makeup attendance</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Checkbox
                    checked={enableRemoteMakeupAttendance}
                    onChange={(event) => setEnableRemoteMakeupAttendance(event.target.checked)}>
                    Enable
                </Checkbox>
            </Col>
            <hr className={styles.line} />
            <span className={styles.title}>Daily (include makeup) attendance rounding</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Radio.Group
                    name=""
                    className={styles.radioGroup}
                    onChange={(event) => {
                        setIsRoundingEnabled(event.target.value === RoundingType.Rounding)
                    }}
                    value={isRoundingEnabled ? RoundingType.Rounding : RoundingType.NoRounding}>
                    <Radio className={styles.radioItem} value={RoundingType.NoRounding}>
                        No Rounding
                    </Radio>
                    <Radio className={styles.radioItem} value={RoundingType.Rounding}>
                        Rounding (Rounding to the minutes not seconds)
                    </Radio>
                </Radio.Group>
                {isRoundingEnabled ? (
                    <>
                        <Col span={24} className={styles.checkboxContainer}>
                            <Checkbox
                                checked={enableDailySpecialRoundingForCheckin}
                                onChange={(event) => setEnableDailySpecialRoundingForCheckin(event.target.checked)}>
                                Special rounding condition for checkin (base on shift start time)
                            </Checkbox>
                        </Col>
                        {enableDailySpecialRoundingForCheckin ? (
                            <RoundingRange
                                isSpecialCondition
                                listData={specialAttendanceRoundingRanges}
                                setListData={setSpecialAttendanceRoundingRanges}
                            />
                        ) : null}
                        <hr className={styles.line} />
                        <span>Rounding logic (Applies to all events - checkin, checkout, break)</span>
                        <RoundingRange listData={attendanceRoundingRanges} setListData={setAttendanceRoundingRanges} />
                    </>
                ) : null}
            </Col>
            <div className={styles.action}>
                <BaseButton
                    title={t("common:action.cancel")}
                    variant="secondary"
                    className={styles.cancelBtn}
                    disabled={!isChanged}
                    onClick={onClickCancelBtn}
                />
                <BaseButton
                    title={t("common:action.save")}
                    onClick={onClickSave}
                    disabled={!isChanged}
                    loading={attendanceRounding.isUpdating}
                />
            </div>
            <BaseLoading isShow={attendanceRounding.isUpdating || attendanceRounding.isFetching} />
        </div>
    )
}

export {DailyAttendance}
