import {AddItemCircleButton, BaseInput} from "components"
import {KlassappTableHOC} from "HOC"
import {cloneDeep} from "lodash"
import {useCallback, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {KlassappTableProps} from "types/common"
import {AttendanceRoundingItem} from "types/settings/general-setting"
import {KlassappTable, KlassappTableHeader} from "uiKit"

type RoundingRangeProps = {
    listData: AttendanceRoundingItem[]
    isSpecialCondition?: boolean
    setListData: (payload: AttendanceRoundingItem[]) => void
} & KlassappTableProps

const RoundingRange = (props: RoundingRangeProps) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        isSpecialCondition,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        onChangePage,
        onChangeRowPerPage,
        isShowTableHeaderAction,
        onUpdateRowData,
        getCurrentData,
        onUpdateTableData,
        onChangeFields,
        onChangeAllFields,
        listData,
        setListData
    } = props
    const {t} = useTranslation(["common"])
    const onChangeNewItemData = (payload: {key: string; value: any; index: number}) => {
        const {key, value, index} = payload
        const newListData = cloneDeep(listData)
        newListData[index][key] = value
        setListData(newListData)
    }

    const renderInput = (payload: {value: any; fieldName: string; index: number}) => {
        const {value, fieldName, index} = payload
        return (
            <BaseInput
                placeholder={fieldName}
                value={value}
                min={0}
                type="number"
                disabledArrow
                onChange={(value) => {
                    let v = value
                    if (fieldName === "value" && value.length && Number(value) > 60) {
                        v = 60
                    } else if (value.length && Number(value) > 60) {
                        v = 60
                    }
                    onChangeNewItemData({key: fieldName, value: v, index})
                }}
            />
        )
    }

    const onClickDeleteMulti = useCallback(async () => {
        const checkedItems = getCurrentData().filter((item: any) => !item.isChecked)
        dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
        setListData(cloneDeep(checkedItems))
    }, [dispatch, getCurrentData, setListData])

    const formattedListData = data.map((item, index) => {
        return {
            ...item,
            fromHtml: renderInput({
                value: item.from,
                fieldName: "from",
                index
            }),
            toHtml: renderInput({
                value: item.to,
                fieldName: "to",
                index
            }),
            valueHtml: renderInput({
                value: item.value,
                fieldName: "value",
                index
            })
        }
    })

    useEffect(() => {
        dispatch({data: listData, total: listData.length})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listData])

    const getFields = () => {
        return ["FROM (minutes)", "TO (minutes)", "ROUNDING VALUE (minutes)"]
    }

    const getPageTitle = () => {
        return "Rounding Range"
    }
    const getColumns = () => {
        return [
            {
                title: "FROM (minutes)",
                field: "fromHtml"
            },
            {
                title: "TO (minutes)",
                field: "toHtml"
            },
            {
                title: "ROUNDING VALUE (minutes)",
                field: "valueHtml"
            }
        ]
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickDeleteMulti])

    const onClickAddItem = useCallback(() => {
        setListData([...listData, {from: undefined, to: undefined, value: undefined, id: listData.length}])
    }, [listData, setListData])

    const getTableHeaderActions = () => {
        const {onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }
    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                isShowAllRecords
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
            />
            <KlassappTable
                columns={columns}
                data={formattedListData}
                menuActions={[]}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={() => {}}
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            {isSpecialCondition && formattedListData?.length ? null : <AddItemCircleButton onClick={onClickAddItem} />}
        </div>
    )
}

export default KlassappTableHOC(RoundingRange)
